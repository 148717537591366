/* eslint-disable no-lone-blocks */
import { useState, useEffect, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import OrderIdInput from './OrderIdInput';
import AddressInfo from './AddressInfo';
import ContactInfo from './ContactInfo';
import Review from './WriteReview';
import { formReducer, validationReducer, progressReducer } from '../Reducers/reducers';
import { isMobile } from 'react-device-detect';
import PurchaseSteps from './PurchaseSteps';
import FormNavButtons from './NavButtons';
import { getInitialSteps } from '../Utilities/postcardUtilities';
import { OrderIdInvalid, RetryButton, GiftCardComplete } from './Alerts';
import ProgressTracker from './ProgressTracker';
import CookieManager from './Cookies';

function GiftCardForPurchase(props) {
    {/*
        Standard landing page for Postcard review funnels. This page type is
        parsed when there is a single purchased product and a single option for
        the free product.

        REQUIRED PROPS:
            brandInfo:
                Brand info object used to pre-fill brand name, legal links,
                query Amazon data from Big Query, disable page titles for 
                sensitive content, etc. Provided by props.
            pageInfo:
                Page info object used to set up page for the offer called in
                the url. Page info object must be in the following form:
                {
                    pageType: String,
                    freeProduct: Array,
                    imageName: String,
                    purchasedProduct: Array,
                    minStar: Number,
                    expiration: DateTime,
                    disclaimerText: String,
                    flagText: Array
                }
    */ }
    const [searchParams, setSearchParams] = useSearchParams();
    {/* searchParams: State used to determine whether user scanned postcard or enterd URL manually. */ }
    const [brandInfo] = useState(props.brandInfo);
    const [showCookieBanner, setShowCookieBanner] = useState(false);
    const [pageInfo] = useState(props.pageInfo);
    const [starRatingMin] = useState(Object.keys(props.pageInfo).includes('minStar') ? props.pageInfo.minStar : 4);
    const [form, dispatchForm] = useReducer(formReducer, {
        firstName: '',
        email: '',
        lastName: '',
        orderId: (''),
        ordersTable: props.brandInfo.ordersTable,
        brand: props.brandInfo.brand,
        domain: props.domain,
        path: props.path,
        submissionTime: '',
        agency: props.brandInfo.agency,
        giftCardValue: props.pageInfo.giftCardValue,
        purchasedProduct: props.pageInfo.purchasedProduct
    });
    {/* form: Form object managed by 'formReducer' function. This object is what is sent to API upon submission. */ }
    const [validation, dispatchValidation] = useReducer(validationReducer, {
        firstName: false,
        lastName: false,
        orderId: false,
        orderIdValid: null,
        email: false,
        redirected: false
    });
    {/* validation: Validation object managed by 'validationReducer' function. 
        This object validates all necessary fields and is checked
        by 'progress' state object to allow fields to be viewed. */}
    {/*  */ }
    const steps = ['Contact Info', 'Head To Amazon', 'Order Id', 'Complete'];
   
    const [progress, dispatchProgress] = useReducer(progressReducer, {
        current: steps[0],
        steps: steps,
        description: ['Verify your Amazon Order Id', 'Enter your contact information for your free product', 'Give us your feedback on our product', 'Post your review on Amazon', 'Enter your address to receive your free product', "You're finished!"],
        skipSteps: []
    })
    const [bgSet, setBgSet] = useState(false);
    const [displayCriteria, setDisplayCriteria] = useState(getDisplayCriteria());
    const [complete, setComplete] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    useEffect(() => {
        function setBackground() {
            {/*
                Calls API using domain and path to get the background image for the page. Runs once on load.    
            */ }
            if (!bgSet) {
                fetch(`https://api.growve.tools:8393/getBackground/${props.domain}${props.path}`, {
                    method: 'GET',
                    mode: 'cors'
                })
                    .then(response => {
                        return response.blob();
                    })
                    .then(blob => {
                        let objectURL = URL.createObjectURL(blob);
                        let newClass = `App py-5 bg-no-repeat bg-cover min-h-screen align-items-middle flex`;
                        document.getElementById('App').setAttribute('class', newClass);
                        document.getElementById('App').style.backgroundImage = `url("${objectURL}")`
                        setBgSet(true);
                    })
            }

        };
        setBackground();
    }, [bgSet, props.domain, props.path])
    function submitForm() {
        setSubmitClicked(true);
        dispatchForm({ type: 'giftCardSubmissionLegacy', checkComplete: checkComplete});
    };

    function checkComplete(boolVal) {
        if (boolVal) {
            setComplete(true)
            dispatchProgress({ type: 'setCurrent', step: 'Complete' })
        } else {
            setComplete(false);
        }
    };

    function getDisplayCriteria() {
        {/*
            Method that determines which set of progress buttons (next, next/back/ back/submit) to
            display for the current step.
        */}
        var nextB = ['Contact Info'];
        var nextBackB = ['Head To Amazon'];
        var subB = ['Order Id'];
        return {
            next: nextB,
            nextBack: nextBackB,
            submit: subB
        };
    }

    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 self-center justify-self-center relative">
            <div className="mx-auto max-w-3xl rounded-3xl">
                <div className='grid grid-cols-1 border-b border-gray-100'>
                    <div className='w-full flex justify-center pb-5 border-gray-200 bg-white/90 pt-5 rounded-t-3xl'>
                        <a href={brandInfo.logolink}>
                            <img className='h-12' src={`https://api.growve.tools:8393/getLogo/${props.domain}${props.path}`} alt="" />
                        </a>
                    </div>
                    <ProgressTracker
                        progress={progress}
                        hidden={complete}
                    />
                </div>
                <div className='px-10 bg-white pt-5'>
                    <OrderIdInput
                        hidden={progress.current !== 'Order Id' || validation.orderIdValid === false || complete}
                        dispatch={dispatchForm}
                        validate={dispatchValidation}
                        form={form}
                        validation={validation}
                        pageInfo={pageInfo}
                    />
                    <ContactInfo
                        hidden={progress.current !== 'Contact Info' || validation.orderIdValid === false || complete}
                        form={form}
                        dispatchForm={dispatchForm}
                        validate={dispatchValidation}
                        validation={validation}
                    />
                    <PurchaseSteps
                        pageInfo={pageInfo}
                        brandInfo={brandInfo}
                        form={form}
                        dispatchForm={dispatchForm}
                        validation={validation}
                        validate={dispatchValidation}
                        hidden={progress.current !== 'Head To Amazon'}
                    />
                    <GiftCardComplete hidden={progress.current !== 'Complete'} email={form.email} />
                    <FormNavButtons
                        hidden={validation.orderIdValid === false || complete}
                        next={displayCriteria.next.includes(progress.current)}
                        submitClicked={submitClicked}
                        nextBack={displayCriteria.nextBack.includes(progress.current)}
                        submit={displayCriteria.submit.includes(progress.current)}

                        onSubmit={submitForm}
                        dispatchProgress={dispatchProgress}
                        progress={progress}
                        isMobile={isMobile}
                        disableNext={progress.current === 'Order Id' ? (!validation.orderId) : progress.current === 'Contact Info' ? !(validation.firstName && validation.lastName && validation.email) : !(validation.redirected)}
                        disableSubmit={!(validation.orderId && validation.firstName && validation.lastName && validation.email )}
                    />
                </div>
                <div className='pb-2 italic text-xs border-t border-gray-200 pt-2 px-4 text-gray-400 bg-white'>
                    {pageInfo.disclaimerText !== null ? pageInfo.disclaimerText : `**Limit one free product per household. No additional purchase is necessary. Please allow 1-2 weeks for delivery. Offer not dependent on type of feedback provided. Offer only valid in the United States of America; void where prohibited. Offer valid while supplies last and subject to change or cancellation at any time. By completing this form you agree to receive future communication from ${brandInfo.brand}.`}
                </div>
                <div className='grid grid-cols-5 py-2.5 border-t border-gray-200 flex align-items-middle font-semibold italic h-fit text-xs bg-white/90 rounded-b-3xl'>
                    <a className='border-r border-gray-200 text-inherit flex justify-center' href={brandInfo.link1url}><span className='inline-block self-center text-center'>{brandInfo.link1text}</span></a>
                    <a className='border-r border-gray-200 text-inherit flex justify-center' href={brandInfo.link2url}><span className='inline-block self-center'>{brandInfo.link2text}</span></a>
                    <a className='border-r border-gray-200 align-middle text-inherit flex justify-center' href={brandInfo.link3url}><span className='inline-block self-center whitespace-nowrap'>{brandInfo.link3text}</span></a>
                    <a className='flex justify-center' href={brandInfo.link4url}><span className='inline-block self-center text-inherit whitespace-nowrap'>{brandInfo.link4text}</span></a>
                    <button className='border-r border-gray-200 align middle justify-center text-inherit' onClick={() => setShowCookieBanner(prev => !prev)}>Cookies</button>
                </div>
            </div>
            <CookieManager brandInfo={brandInfo} showBanner={showCookieBanner} setShowBanner={setShowCookieBanner} />
        </div>
    )


};
export default GiftCardForPurchase