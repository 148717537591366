import { CheckIcon } from '@heroicons/react/20/solid'
import { useState, useEffect } from 'react';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PostReviewSteps(props) {
    const [amazonLink, setAmazonLink] = useState(`https://www.amazon.com/review/review-your-purchases/?_encoding=UTF8&asin=${props.form.purchasedProduct.asin}`)
    const [reviewSteps, setReviewSteps] = useState([
        { name: 'Copy Your Review', description: 'Click your review above to copy the Text', href:'#', status: 'current' },
        { name: 'Head to Amazon', description: 'Click here to be redirected to Amazon', href: amazonLink, status: 'upcoming' },
        { name: 'Post Your Review', description: 'Post your review on Amazon', href: '#', status: 'upcoming' },
        { name: 'Complete Your Offer', description: 'Return to this page to complete your offer', href: '#', status: 'upcoming' }
    ]);
    const [redirected, setRedirected] = useState(false);
    const [asin, setAsin] = useState(props.form.purchasedProduct === '...' ? '...' : props.form.purchasedProduct.asin);

    useEffect(() => {
        function updateAmazonLink() {
            if (props.form.purchasedProduct !== '...' && props.form.purchasedProduct.asin !== asin) {
                setAmazonLink(`https://www.amazon.com/review/review-your-purchases/?_encoding=UTF8&asin=${props.form.purchasedProduct.asin}`);
                setAsin(props.form.purchasedProduct.asin);
            } else if (props.form.purchasedProduct === '...' && asin !== '...') {
                setAsin('...');
            } else {
                return;
            };
        }
        updateAmazonLink();
    }, [props]);

    useEffect(() => {
        function setCopyComplete() {
            if (!redirected && props.copied && reviewSteps[0].status === 'current') {
                setReviewSteps([
                    { name: 'Copy Your Review', description: 'Click your review above to copy the Text', href: '#', status: 'complete' },
                    { name: 'Head to Amazon', description: 'Click here to be redirected to Amazon', href: amazonLink, status: 'current' },
                    { name: 'Post Your Review', description: 'Post your review on Amazon', href:'#', status: 'upcoming' },
                    { name: 'Complete Your Offer', description: 'Return to this page to complete your offer', href:'#', status: 'upcoming' }
                ])
            }
        };
        setCopyComplete();
    }, [props.copied, redirected, reviewSteps]);

    useEffect(() => {
        function setHeadToAmazonComplete() {
            if (props.copied && redirected && reviewSteps[1].status === 'current') {
                setReviewSteps([
                    { name: 'Copy Your Review', description: 'Click your review above to copy the Text', href:'#', status: 'complete' },
                    { name: 'Head to Amazon', description: 'Click here to be redirected to Amazon', href: amazonLink, status: 'complete' },
                    { name: 'Post Your Review', description: 'Post your review on Amazon', href: '#', status: 'current' },
                    { name: 'Complete Your Offer', description: 'Return to this page to complete your offer', href: '#', status: 'upcoming' }
                ])
            }
        };
        setHeadToAmazonComplete();
    }, [redirected, props.copied, reviewSteps]);

    useEffect(() => {
        function completed() {
            if (props.copied && redirected && reviewSteps[2].status === 'current') {
                setInterval(() => {
                    setReviewSteps([
                        { name: 'Copy Your Review', description: 'Click your review above to copy the Text', href: '#', status: 'complete' },
                        { name: 'Head to Amazon', description: 'Click here to be redirected to Amazon', href: amazonLink, status: 'complete' },
                        { name: 'Post Your Review', description: 'Post your review on Amazon', href: '#', status: 'complete' },
                        { name: 'Complete Your Offer', description: 'Return to this page to complete your offer', href: '#', status: 'complete' }
                    ]);
                    props.validate({ type: 'setRedirected' });
                }
                , 10000);
            }
        };
        completed();
    }, [props.copied, redirected, reviewSteps]);
    return (
        <nav aria-label="Progress" className='flex justify-center my-5'>
            <ol role="list" className="overflow-hidden">
                {reviewSteps.map((step, stepIdx) => (
                    <li key={step.name} className={classNames(stepIdx !== reviewSteps.length - 1 ? 'pb-5' : '', 'relative')}>
                        {step.status === 'complete' ? (
                            <>
                                {stepIdx !== reviewSteps.length - 1 ? (
                                    <div className="pointer-events-none absolute left-2 top-2 mt-0.5 -ml-px h-full w-0.5 bg-indigo-600" aria-hidden="true" />
                                ) : null}
                                <a className=" group relative flex items-start">
                                    <span className="flex h-9 items-center">
                                        <span className="relative z-10 flex h-4 w-4 items-center justify-center rounded-full bg-indigo-600">
                                            <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex w-full min-w-0 flex-col text-center justify-end">
                                        <span className="pointer-events-none text-xs font-medium text-center">{step.name}</span>
                                        <span className="pointer-events-none text-xs text-gray-500 text-center">{step.description}</span>
                                    </span>
                                </a>
                            </>
                        ) : step.status === 'current' && step.href === '#' ? (
                            <>
                                {stepIdx !== reviewSteps.length - 1 ? (
                                        <div className="pointer-events-none absolute left-2 top-2 mt-0.5 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true" />
                                ) : null}
                                    <a target='_blank' rel='noopener noreferrer' className=" group relative flex items-start" aria-current="step">
                                    <span className="flex h-9 items-center" aria-hidden="true">
                                        <span className="relative z-10 flex h-4 w-4 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                                            <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex w-full min-w-0 flex-col justify-end">
                                        <span className="pointer-events-none text-xs font-medium text-indigo-600 text-center">{step.name}</span>
                                        <span className="pointer-events-none text-xs text-gray-500 text-center">{step.description}</span>
                                    </span>
                                </a>
                            </>
                            ) : step.status === 'current' && step.href !== '#' ? (
                                <>
                                    {stepIdx !== reviewSteps.length - 1 ? (
                                        <div className="absolute left-2 top-2 -ml-px h-full w-0.5 mt-0.5 bg-gray-300" aria-hidden="true" />
                                    ) : null}
                                        <a onClick={() => { setRedirected(true); } } href={step.href} target='_blank' rel='noopener noreferrer' className="group relative flex items-start" aria-current="step">
                                        <span className="flex h-9 items-center" aria-hidden="true">
                                            <span className="relative z-10 flex h-4 w-4 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                                                <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                                            </span>
                                        </span>
                                        <span className="ml-4 flex w-full min-w-0 flex-col justify-end">
                                            <span className="text-xs font-medium text-indigo-600 text-center">{step.name}</span>
                                            <span className="text-xs text-gray-500 text0center">{step.description}</span>
                                        </span>
                                    </a>
                                </>
                            ) : (
                            <>
                                {stepIdx !== reviewSteps.length - 1 ? (
                                    <div className="pointer-events-none absolute left-2 top-2 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                                ) : null}
                                            <a target='_blank' rel='noopener noreferrer' href={step.href} className="group relative flex items-start">
                                    <span className="flex h-9 items-center" aria-hidden="true">
                                        <span className="relative z-10 flex h-4 w-4 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                            <span className="h-2.5 w-2.5 rounded-full bg-transparent" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex w-full min-w-0 flex-col">
                                        <span className="pointer-events-none text-xs font-medium text-gray-500">{step.name}</span>
                                        <span className="pointer-events-none text-xs text-gray-500">{step.description}</span>
                                    </span>
                                </a>
                            </>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    )
}
