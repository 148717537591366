export default function SelectFreeProduct(props) {
    return (
        <form className={props.hidden ? 'hidden' : ''}>
            <div className="space-y-12">
                <div>
                    <h2 className="text-lg font-semibold leading-7 text-gray-900">Select Your Free Product</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">Select your free product below</p>

                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">

                        <div className="col-span-full pb-10">
                            <label htmlFor="freeProduct" className="block text-sm font-medium leading-6 text-gray-900 text-start">
                                Free Product
                            </label>
                            <div className="mt-2">
                                <select
                                    id="freeProduct"
                                    name="freeProduct"
                                    onChange={(e) => { console.log(props.pageInfo.freeProduct.filter(x => x.asin === e.target.value)[0]);  props.dispatchForm({ type: 'simpleUpdate', field: 'freeProduct', value: props.pageInfo.freeProduct.filter(x => x.asin === e.target.value)[0] }); }}
                                    value={props.form.freeProduct === '...' ? '...' : props.form.freeProduct.asin}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 text-center"
                                >
                                    <option disabled value='...'>...</option>
                                    {props.pageInfo.freeProduct.map(a => {
                                        return (
                                            <option key={`freeOption_${a.asin}`} className="font-medium py-5 text-center" value={a.asin}>{a.brand} {a.title}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}