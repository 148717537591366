/* eslint-disable no-lone-blocks */
import { useState, useEffect } from 'react';
import { StarIcon as StarSolid } from '@heroicons/react/20/solid';
import { StarIcon as StarOutline } from '@heroicons/react/24/outline';
export default function Review(props) {
    {/* 
         Review form field used for Review Postcards. Contains star rating picker, and a managed long-text field.

         WARNING >>>>> DO NOT TOUCH THIS CODE. I have no idea what makes the 'rating' state value update on click,
            and I would rather not have to find out at this point. Please don't touch. If this ever does stop working,
            try adding setRating(num) to the onClick() method.
         
         REQUIRED PROPS:
            hidden:
                Boolean value to show/hide component provided by parent component.
            form:
                Object containing the landing page's fields for submission.
                Used to store entered value.
            dispatch:
                Reducer required for 'form' prop. 
            validate:
                Reducer required for validation object.
            value:
                Value of reviewText in 'form' object provided by parent component. Don't ask why I did
                it like this, but we're going to live with it. It's used in too many places now.
            rating:
                Default rating of the product. Defaults to 0 so that component appears unset.
    */}
    
    {/* rating: This state defaults to 0 to appear 'unset'. See 'ratingText state below. Cannot be set back to 0.
        Round and round and round it goes! How it changes, nobody knows. */ }
    const [ratingText] = useState({
        0: 'How did we do?',
        1: 'Poor',
        2: 'Not good',
        3: 'Below average',
        4: 'Still needs work...',
        5: 'Excellent - 5 Stars!'
    })
    function onMouseOver(num) {
        {/* Method used to fill moused over elements to indicate which star rating is being chosen. */}
        return;
    };
    function onMouseOut() {
        {/* Method used to unfill components that were moused over on mouse out, displaying the current set star rating. */ }
        return;
    }
    function onClick(num) {
        {/* Method used to handle and set rating both on local component and Don't touch. Don't know how it sets 'rating' state.
            Leave me alone, please leave me alone......    
        */ }
        props.dispatch({ type: 'simpleUpdate', field: 'starRating', value: num });
        props.validate({ type: 'starRatingValidation', field: 'starRating', value: num });
    }

    return (
        <form className={props.hidden ? 'hidden' : 'w-full'}>
            <div className="space-y-12 pt-3 w-full">
                <div>
                    <h2 className="text-lg font-semibold leading-7 text-gray-900">We strive for excellence</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600 font-bold">How did we do?</p>
                    <div className="flex gap-x-0.5 text-yellow-400 justify-center">
                        {props.form.starRating >= 1 ? <StarSolid onClick={() => onClick(1)} onMouseOver={() => onMouseOver(1)} onMouseOut={() => onMouseOut()} className="h-10 w-10 flex-none" aria-hidden="true" /> : <StarOutline onClick={() => onClick(1)} onMouseOver={() => onMouseOver(1)} onMouseOut={() => onMouseOut()} className="h-10 w-10 flex-none" aria-hidden="true" />}
                        {props.form.starRating >= 2 ? <StarSolid onClick={() => onClick(2)} onMouseOver={() => onMouseOver(2)} onMouseOut={() => onMouseOut()} className="h-10 w-10 flex-none" aria-hidden="true" /> : <StarOutline onClick={() => onClick(2)} onMouseOver={() => onMouseOver(2)} onMouseOut={() => onMouseOut()} className="h-10 w-10 flex-none" aria-hidden="true" />}
                        {props.form.starRating >= 3 ? <StarSolid onClick={() => onClick(3)} onMouseOver={() => onMouseOver(3)} onMouseOut={() => onMouseOut()} className="h-10 w-10 flex-none" aria-hidden="true" /> : <StarOutline onClick={() => onClick(3)} onMouseOver={() => onMouseOver(3)} onMouseOut={() => onMouseOut()} className="h-10 w-10 flex-none" aria-hidden="true" />}
                        {props.form.starRating >= 4 ? <StarSolid onClick={() => onClick(4)} onMouseOver={() => onMouseOver(4)} onMouseOut={() => onMouseOut()} className="h-10 w-10 flex-none" aria-hidden="true" /> : <StarOutline onClick={() => onClick(4)} onMouseOver={() => onMouseOver(4)} onMouseOut={() => onMouseOut()} className="h-10 w-10 flex-none" aria-hidden="true" />}
                        {props.form.starRating === 5 ? <StarSolid onClick={() => onClick(5)} onMouseOver={() => onMouseOver(5)} onMouseOut={() => onMouseOut()} className="h-10 w-10 flex-none" aria-hidden="true" /> : <StarOutline onClick={() => onClick(5)} onMouseOver={() => onMouseOver(5)} onMouseOut={() => onMouseOut()} className="h-10 w-10 flex-none" aria-hidden="true" />}
                    </div>
                    <p className="mt-1 text-sm leading-6 text-gray-600 italic">{ratingText[props.form.starRating]}</p>
                    <label htmlFor="feedback" className="block text-sm font-medium leading-6 text-gray-900">
                        Share your experience below:
                    </label>
                    <div className="mt-2">
                        <textarea
                            id="reviewText"
                            name="reviewText"
                            rows={3}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={props.value}
                            onChange={(e) => { props.dispatch({ type: 'simpleUpdate', field: 'reviewText', value: e.target.value }); props.validate({ type: 'reviewTextValidation', field: 'reviewText', value: e.target.value }) } }
                        />
                    </div>
                    <p className="mt-1 ml-5 text-sm text-start leading-6 text-gray-600 italic">Minimum 50 Characters: {props.value.length}/50</p>
                </div>
            </div>
        </form>
    )
};