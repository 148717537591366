
import { useState, useEffect, useReducer } from 'react';
import { surveyReducer } from '../Reducers/reducers';
import { TextInput, LongTextInput, Toggle, Dropdown } from './FormFields';
function FormField(props) {
    if (props.type === 'Text') {
        return (
            <TextInput
                label={props.question}
                onChange={props.onChange}
                value={props.value === null ? '' : props.value}
            />
        )
    } else if (props.type === 'LongText') {
        return (
            <LongTextInput
                label={props.question}
                onChange={props.onChange}
                value={props.value === null ? '' : props.value}
            />
        )
    } else if (props.type === 'Toggle') {
        return(
            <Toggle
                label={props.question}
                onChange={props.onChange}
                checked={props.value}
            />
        )
    } else if (props.type === 'Dropdown') {
        return(
            <Dropdown
                label={props.question}
                onChange={props.onChange}
                value={props.value === null ? '' : props.value}
                options={props.options}
            />
        )
    };
}
export default function SurveyForm(props) {
    const [survey, dispatch] = useReducer(surveyReducer, initialData());
    const [jsonString, setJsonString] = useState('');
    const [valid, setValid] = useState(false);

    // useEffect(() => {
    //     function checkValidation() {
    //         if (valid) {
    //             setJsonString(JSON.stringify(survey));
    //             return;
    //         } else {
    //             return;
    //         }
    //     };
    //     checkValidation();
        
    // }, [survey]);

    useEffect(() => {
        var str = JSON.stringify(survey);
        function dispatchString() {
            if (validate() && props.form.survey !== str) {
                
                setValid(true)
                setJsonString(str);
                props.feedChange({type: 'simpleUpdate', field: 'survey', value: str});
                props.feedValidation({type: 'explicitlySet', value: valid, field: 'survey'});
                return;
            } else {
                setValid(false);
                return;
            };
        };
        dispatchString();

    }, [valid, jsonString, props.feedChange, props.feedValidation, props]);

    function validate() {
        let validForm = true;
        for (const question in survey) {
            if (survey[question] !== null && ['string'].includes(typeof survey[question]) && !['', ' '].includes(survey[question])) {
                continue;
            } else if ([true, false].includes(survey[question])) {
                continue;
            } else {
                validForm = false;
                break
            }
        };
        return validForm
    };

    useEffect(() => {
        setValid(validate());
    }, [survey, props]);
    function onChange(field, value) {
        dispatch({ type: 'set', field: field, value: value })
    }

    function initialData() {
        const data = {}
        for (const question of props.questions) {
            if (question.type === 'Toggle') {
                data[question.question] = false;
            } else {
                data[question.question] = null
            }
            
        }
        return data
    }
    return(
        <form className={props.hidden ? 'hidden' : '' }>
            <div className="space-y-12">
                {props.questions.map((question, idx) => {
                    return <FormField key={`${question.type}-question-${idx}`} value={survey[question.question]} onChange={(v) => onChange(question.question, v)} {...question} />
                })}
            </div>
        </form>
    )
};