import cookieIcon from '../Assets/cookies-icon.png';
import { useCookies } from 'react-cookie'
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

function CookieBanner(props) {
    return (
        <div className={props.show ? "fixed inset-x-0 bottom-2.5 h-40 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8" : "hidden"}>
            <p className="max-w-4xl text-sm leading-6 text-gray-900">
                This website uses cookies to improve user experience. Accepting our cookies is optional, but recommended.
                See our &nbsp;
                <a href={props.brandInfo.cookiePolicy} target="_blank" rel="noopener noreferrer" className="font-semibold text-indigo-600" >
                    {props.brandInfo.cookiePolicyName}
                </a>
                .
            </p>
            <div className="flex flex-none items-center gap-x-5 pb-6">
                <button
                    type="button"
                    id='acceptCookiesButton'
                    onClick={props.acceptCookies}
                    className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                >
                    Accept all
                </button>
                <button
                    type="button"
                    id='rejectCookiesButton'
                    onClick={props.rejectCookies}
                    className="text-sm font-semibold leading-6 text-gray-900">
                    Reject all
                </button>
            </div>
        </div>
    )
};


function CookieButton(props) {
    return (
        <div className={props.show ? "flex self-end items-end justify-start ml-5 isolate absolute" : "hidden"}>
            <button
                className='w-12 h-12 rounded-full bg-blue-600'
                onClick={props.onClick}
            >
                <img alt='cookie' src={cookieIcon} className='w-6 h-6 mx-auto'></img>
            </button>
        </div>
    )
};

function CookieManager(props) {
    const [showBanner, setShowBanner] = useState(false);
    const [acceptCookies, setAcceptCookies] = useState(null);
    const [policyName, setPolicyName] = useState('');
    const [policyLink, setPolicyLink] = useState('#');

    useEffect(() => {
        function checkForPolicy() {
            if (acceptCookies === null && props.brandInfo && Object.keys(props.brandInfo).includes('cookiePolicy')) {
                props.setShowBanner(true);
                setPolicyLink(props.brandInfo.cookiePolicy);
                if (Object.keys(props.brandInfo).includes('cookiePolicyName')) {
                    setPolicyName(props.brandInfo.cookiePolicyName);
                } else {
                    setPolicyName('Cookie Policy')
                }
            } else {
                return;
            }
        };
        checkForPolicy();
    }, [props.brandInfo, acceptCookies]);

    return (
        <CookieBanner
            show={props.showBanner}
            acceptCookies={() => { setAcceptCookies(true); props.setShowBanner(false); }}
            rejectCookies={() => { setAcceptCookies(false); props.setShowBanner(false); }}
            brandInfo={props.brandInfo} />
    )
    
}

//function CookieManager(props) {
//    const [searchParams, setSearchParams] = useSearchParams();
//    function getDomain() {
//        if (window.location.hostname === 'localhost') {
//            return searchParams.get('domain')
//        } else {
//            return window.location.hostname
//        }
//    };
//    const [domain] = useState(getDomain());
//    function getPrivacyPolicy() {
//        fetch(`https://api.growve.tools:8393/getPrivacyPolicy/${domain}`, {
//            method: 'GET',
//            mode: 'cors'
//        })
//            .then(response => {
//                return response.json
//            })
//            .then(json => {
//                if (Object.keys(json).includes('privacyPolicy')) {
//                    setPolicy(json.privacyPolicy);
//                } else {
//                    setPolicy(null);
//                }
                
//            })
//            .catch(rejected => {
//                setPolicy(null);
//            })
//    };
//    const [policy, setPolicy] = useState(getPrivacyPolicy());
//    const [cookies, setCookie, removeCookie] = useCookies(['grwv_consent']);
//    const [showBanner, setShowBanner] = useState(cookies.grwv_consent === undefined ? true : cookies.grwv_consent[domain] === true ? false : true);
//    useEffect(() => {
//        function setInitialCookie() {
//            if (cookies.grwv_consent === undefined) {
//                setCookie('grwv_consent', { [domain]: false }, { path: '/' })
//                window.dataLayer.push({ 'consent_all': false })
//            } else if (cookies.grwv_consent[domain] === undefined) {
//                setCookie('grwv_consent', { ...cookies.grwv_consent, [domain]: false }, { path: '/' });
//                window.dataLayer.push({'consent_all': false})
//            } else {
//                window.dataLayer.push({ 'consent_all': cookies.grwv_consent[domain] })
//                return
//            };
//        };
//        setInitialCookie();
//    }, []);
//    return (
//        <>
//            <CookieBanner show={policy !== null ? showBanner : false} acceptCookies={() => { setCookie('grwv_consent', { ...cookies.grwv_consent, [domain]: true }, { path: '/' }); setShowBanner(false) }} rejectCookies={() => { setCookie('grwv_consent', { ...cookies.grwv_consent, [domain]: false }, { path: '/' }); setShowBanner(false) }} privacyPolicy={policy} />
//            <CookieButton onClick={() => setShowBanner(true)} show={policy !== null ? !showBanner : false} />
//        </>
//    )
//}

export default CookieManager



