/* eslint-disable no-lone-blocks */
function formReducer(state, action) {
    {/*
        Reducer used with form objects. Holds values that will be sent to API on submission.
        Most fields are simply added/edited in the state object, except for amazonOrderId, which
        is formats Amazon Order IDs with dashes (12312345671234567 becomes 123-1234567-1234567).
    */}
    var formattedValue;
    switch (action.type) {
        case 'simpleUpdate':
            return {
                ...state,
                [action.field]: action.value
            };
        case 'amazonOrderId':
            if (action.keyStroke === 'deleteContentBackward') {
                formattedValue = action.value;
            } else {
                if (action.value.length === 3) {
                    formattedValue = action.value + '-';
                } else if (action.value.length === 11) {
                    formattedValue = action.value + '-'
                } else {
                    formattedValue = action.value
                };
            };
            return {
                ...state,
                [action.field]: formattedValue
            };
        case 'reviewSubmission':
            fetch('https://api.growve.tools:8393/v2/formSubmission', {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(state)
            })
                .then(response => {
                    action.checkComplete(response.ok);
                });
            return state
        case 'redemptionSubmission':
            fetch('https://api.growve.tools:8393/v2/redemptionFormSubmission', {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(state)
            })
                .then(response => {
                    action.checkComplete(response.ok);
                });
            return state;
        case 'reviewSubmissionLegacy':
            console.log('State: ');
            console.log(state);
            var form = new FormData();
            var fulfillmentId = `GR-${state.orderId.replace('-', '')}-${state.zipCode}`
            form.append('orderId', state.orderId);
            form.append('firstName', state.firstName);
            form.append('lastName', state.lastName);
            form.append('email', state.email);
            form.append('starRating', state.starRating);
            form.append('reviewText', state.reviewText);
            form.append('brand', state.brand);
            form.append('asin', state.purchasedProduct.asin);
            form.append('fulfillmentId', fulfillmentId);
            form.append('domain', state.domain);
            form.append('path', state.path.replace('/', ''));
            form.append('testOrder', false);
            form.append('productChoice', state.freeProduct.asin);
            form.append('ordersTable', state.ordersTable);
            form.append('addressOne', state.address1);
            form.append('addressTwo', (['', null, undefined].includes(state.address2) ? 'null' : state.address2));
            form.append('city', state.city);
            form.append('zipCode', state.zipCode);
            form.append('state', state.zipCode);
            form.append('submissionTime', Date.now());
            form.append('agency', state.agency);
            var complete = {
                ...state
            };
            fetch('https://api.growve.tools:8393/formSubmission', {
                method: 'POST',
                mode: 'cors',
                body: form
            })
                .then(response => {
                    action.checkComplete(response.ok);
                });
            return complete

        case 'giftCardSubmissionLegacy':
            var purchaseForm = {
                firstName: state.firstName,
                lastName: state.lastName,
                orderId: state.orderId,
                email: state.email,
                redirected: true,
                asin: Array.from(state.purchasedProduct, x => x.asin),
                brand: state.brand,
                domain: state.domain,
                path: state.path,
                ordersTable: state.ordersTable,
                agency: state.agency,
                giftCardValue: state.giftCardValue
            };
            fetch('https://api.growve.tools:8393/giftCardSubmissions/new', {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(purchaseForm)
            })
                .then(response => {
                    action.checkComplete(response.ok);
                })
            return state;
        case 'surveyUpdate':
            return {
                ...state,
                survey: action.value 
            };
        case 'uploadAgeVerification':
            return {
                ...state,
                ageVerified: true,
                ageVerification: action.value
            }
        default:
            return state;
    };
};

function validationReducer(state, action) {
    {/*
        Reducter to handle validation object to ensure formatting/values/etc. are correct
        upon submission.
    */ }
    switch (action.type) {
        case 'nameValidation':
            {/*
                Checks that name (first || last) is at least 2 characters long before allowing
                user to proceed.
            */}
            if (action.value.length >= 2) {
                return {
                    ...state,
                    [action.field]: true
                };
            } else {
                return {
                    ...state,
                    [action.field]: false
                }
            };
            
        case 'zipValidation':
            {/*
                Validates zip code length to be either 5 or 10 before allowing user to proceed..    
            */ }
            if (action.value.length === 5 && !isNaN(action.value)) {
                return {
                    ...state,
                    [action.field]: true
                }
            } else if (action.value.length === 10 && action.value.at(5) === '-' && !isNaN(action.value.slice(0, 5)) && !isNaN(action.value.slice(6))) {
                return {
                    ...state,
                    [action.field]: true
                }
            } else {
                return {
                    ...state,
                    [action.field]: false
                }
            }
            
        case 'orderIdValidation':
            {/*
                Validates Order ID formatting (19 characters, dashed at char 4 and 11) before allowing user to proceed.)    
            */ }
            if (action.value.length === 19 && !isNaN(action.value.slice(0, 3) && !isNaN(action.value.slice(4, 11) && !isNaN(action.value.slice(12))))) {
                return {
                    ...state,
                    [action.field]: true
                }
            } else {
                return {
                    ...state,
                    [action.field]: false
                }
            };
        case 'walmartOrderValidation':
            if (action.value.length >= 10 && action.value.length < 16 && !isNaN(action.value)) {
                return {
                    ...state,
                    [action.field]: true
                }
            } else {
                return {
                    ...state,
                    [action.field]: false
                }
            }
        case 'stateValidation':
            {/*
                Ensures state value isn't set to the default value ('...') before allowing user to continue.
                (values are controlled, so no other validation is needed)
            */ }
            if (action.value !== '...') {
                return {
                    ...state,
                    [action.field]: true
                }
            } else {
                return {
                    ...state,
                    [action.field]: false
                }
            };
        case 'emailValidation':
            {/*
                Validates email value to ensure it includes a top level domain (.com), '@', and an address with at least
                2 characters before allowing user to proceed.
            */}
            if (action.value.includes('@')) {
                const emailParts = action.value.split('@')
                let domainParts;
                if (emailParts.length > 1) {
                    domainParts = emailParts[1].split('.');
                } else {
                    domainParts = ['']
                };
                let validEmail;
                if (emailParts.length === 2 && emailParts[0].length >= 2 && domainParts[0].length >= 2 && domainParts.length >= 2 && domainParts[1].length >= 2) {
                    validEmail = true;
                } else {
                    validEmail = false;
                };
                return {
                    ...state,
                    [action.field]: validEmail
                };
            } else {
                return {
                    ...state,
                    [action.field]: false
                }
            };
        case 'reviewTextValidation':
            {/*
                Ensures reviewText field is at least 50 characters in length before
                allowing user to proceed.
            */}
            if (action.value.length >= 50) {
                return {
                    ...state,
                    [action.field]: true
                };
            } else {
                return {
                    ...state,
                    [action.field]: false
                }
            };
        case 'starRatingValidation':
            {/*
                Ensures star rating is not set to default (0) before allowing user
                to proceed.
            */}
            if (action.value > 0) {
                return {
                    ...state,
                    [action.field]: true
                };
            } else {
                return {
                    ...state,
                    [action.field]: false
                }
            }
        case 'verifyOrderId':
            {/*
                Sets value for Order ID verification check 
                (ensures Order ID is a legitimate Order ID in BigQuery). User
                cannot proceed if false.
            */}
            return {
                ...state,
                orderIdValid: action.value
            };
        case 'setCopied':
            {/*
                Sets 'copied' value, which is set when user copies their review text
                before being redirected to Amazon.
            */}
            return {
                ...state,
                copied: true
            }
        case 'setRedirected':
            {/*
                Sets 'redirected' value, which is set when the user is
                redirected to Amazon.
            */}
            return {
                ...state,
                redirected: true
            };
        case 'redemptionCodeValid':
            return {
                ...state,
                redemptionCodeValid: action.value
            };
        case 'redemptionCode':
            return {
                ...state,
                redemptionCode: action.value
            };
        case 'explicitlySet':
            {/*
                Case used to explicitly set values when certain fields are skipped
                or a validation isn't necessary for the particular case (i.e. allows 
                form to submit if a digital product was selected and address is not needed.
                This case allows the validation to be set to 'true', allowing validation checks
                to pass.)
            */}
            return {
                ...state,
                [action.field]: action.value
            };
        default:
            return state;
    }
};



function progressReducer(state, action) {
    {/*
        Reducer used to manage the user's progress and the progress display on the landing page.    
    */}
    var cIdx;
    var nIdx;
    switch (action.type) {
        case 'next':
            {/*
                Uses the steps initially provided to the state value, and move to the next step.
                This case will also scroll to the top so the user is always at the top of the page
                on a new step.
            */}
            window.scrollTo(0, 0);
            cIdx = state.steps.indexOf(state.current);
            nIdx = state.skipSteps.includes(state.steps[cIdx + 1]) ? (cIdx + 2) : (cIdx + 1);
            return {
                ...state,
                current: state.steps[nIdx]
            };
        case 'back':
            {/*
                Uses the steps initially provided to the state value, and move to the previous step.
                This case will also scroll to the top so the user is always at the top of the page when
                entering a different step.
            */}
            window.scrollTo(0, 0);
            cIdx = state.steps.indexOf(state.current);
            nIdx = state.skipSteps.includes(state.steps[cIdx - 1]) ? (cIdx - 2) : (cIdx - 1);
            return {
                ...state,
                current: state.steps[nIdx]
            };
        case 'skipStep':
            {/*
                Removes a step from provided steps if certain criteria have been met by parent component.
                
                i.e. Address info is not needed because the offer is for a digital product - this case will
                remove the addressInfo step.
            */}
            return {
                ...state,
                skipSteps: [...state.skipSteps, action.step]
            }
        case 'doNotSkip':
            {/*
                Replaces a previously removed step into provided steps 
                if certain criteria have been met by parent component.
                
                i.e. Address info was not needed because the user chose a digital product 
                but changed their mind and chose a physical product instead - this case will
                replace the previously removed addressInfo step.
            */}
            return {
                ...state,
                skipSteps: state.skipSteps.filter(x => x !== action.step)
            };
        case 'setCurrent':
            {/*
                Explicitly sets current step. Typically used during initialization of a page.    
            */}
            window.scrollTo(0, 0);
            return {
                ...state,
                current: action.step
            }
        default:
            return state;
    }
};

function surveyReducer(state, action) {
    switch (action.type) {
        case 'set':
            return {
                ...state,
                [action.field]: action.value
            };
        default:
            return state;
    }
}

function siteInfoReducer(state, action) {
    {/*
        IGNORE ME FOR NOW, WILL BE UPDATED LATER.    
    */}
    let newObject;
    switch (action.type) {
        case 'initialData':
            return action.initial
        case 'valueChange':
            console.log(action);
            return {
                ...state,
                [action.domain]: {
                    ...state[action.domain],
                    [action.keyname]: {
                        ...state[action.domain][action.keyname],
                        [action.field]: action.value
                    }
                }
            }
        case 'newDomain':
            return {
                ...state,
                [action.newDomain]: {
                    differentDomain: action.primaryDomain
                }
            };
        case 'removePage':
            console.log(action);
            newObject = state;
            delete newObject[action.domain][action.path];
            console.log(newObject);
            return { ...newObject };
        default:
            return state
    }
}

export { formReducer, validationReducer, progressReducer, siteInfoReducer, surveyReducer }