import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useState, useEffect, Dispatch, SetStateAction, Fragment } from 'react';
import { Switch, Listbox, Transition } from '@headlessui/react';


export function TextInput(props) {
    return(
        <div>
            <label htmlFor={props.id} className="ml-px block pl-4 text-sm font-medium leading-6 text-gray-900">
                {props.label}
            </label>
            <div className="mt-2">
                <input
                type="text"
                name="name"
                onChange={(e) => props.onChange(e.target.value)}
                id={props.id}
                className="block w-full rounded-lg border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder={props.placeholder ? props.placeholder : undefined}
                />
            </div>
        </div>
    )
};

export function LongTextInput(props) {
  return(
      <div>
          <label htmlFor={props.id} className="ml-px block pl-4 text-sm font-medium leading-6 text-gray-900">
              {props.label}
          </label>
          <div className="mt-2">
              <textarea
              name="name"
              onChange={(e) => props.onChange(e.target.value)}
              id={props.id}
              className="block w-full rounded-lg border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={props.placeholder ? props.placeholder : undefined}
              />
          </div>
      </div>
  )
};



export function Toggle(props) {
    return(
        <Switch.Group>
            <div className='flex justify-center items-center'>
                <Switch.Label className="ml-px block px-4 text-sm font-medium leading-6 text-gray-900">
                    {props.label}
                </Switch.Label>
                <Switch
                    checked={props.checked}
                    onChange={props.onChange}
                    className={`${
                        props.checked ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex items-center h-6 w-11 flex-shrink-0 cursor-pointer rounded-lg border-2 border-transparent tranition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                >
                    <span
                        className={`${
                        props.checked ? 'translate-x-6' : 'translate-x-1'
                        } inline-block  h-4 w-4 transform rounded-lg bg-white transition-transform`}
                    />
                </Switch>
            </div>
        </Switch.Group>
    )
};



// const people = [
//   { name: 'Wade Cooper' },
//   { name: 'Arlene Mccoy' },
//   { name: 'Devon Webb' },
//   { name: 'Tom Cook' },
//   { name: 'Tanya Fox' },
//   { name: 'Hellen Schmidt' },
// ]



export function Dropdown(props) {
  const [selected, setSelected] = useState(props.value);

  useEffect(() => {
    props.onChange(selected);
  }, [selected]);

  return (
    <div id={props.id} className="flex items-center justify-center w-full">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Label>{props.label}</Listbox.Label>
          <Listbox.Button className="relative w-full h-[2.25rem] cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {props.options.map((option, idx) => (
                <Listbox.Option
                  key={`${props.id}-option-${idx}`}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                        <>
                        <span
                            className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                            }`}
                        >
                            {option}
                        </span>
                        {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                        ) : null}
                        </>
                    )
                  }
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
