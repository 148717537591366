import { useState, useEffect, useReducer } from 'react';
import { PencilIcon, XMarkIcon, CheckIcon } from '@heroicons/react/24/solid';
import SubmitButton from './Modals';

class ObjectManager {
    constructor(initial) {
        this.object = initial
    };
    add(newObject) {
        this.object = {
            ...this.object,
            ...newObject
        };
    };
    remove(keys) {
        let rebuild = {}
        for (const x of Object.keys(this.object)) {
            if (!keys.includes(x)) {
                rebuild[x] = this.object[x]
            } else {
                continue;
            }
        }
        this.object = rebuild
    };
    update(key, value) {
        this.object[key] = value
    };
    value() {
        return this.object;
    }
}

function brandInfo(state, action) {
    var info;
    var keys;
    var check;
    var required;
    var fix;
    var schema;
    var fields;
    var masterInfo;
    switch (action.type) {
        case 'init':
            info = new ObjectManager({ ready: false, brand: action.brand })
            //masterInfo = {};
            //info = { ready: false, brand: action.brand };
            //schema = {};
            fetch(`https://api.growve.tools:8393/v2/info/${action.brand.primaryDomain}/brandInfo`, { method: 'GET', mode: 'cors' })
                .then(response => {
                    return response.json()
                })
                .then(json => {
                    info.add({ initial: json.info, form: json.info });
                    return info;
                    //let infoBase = {}
                    //infoBase.initial = json.info;
                    //infoBase.form = json.info;
                    //return infoBase;
                })
                .then(infoBase => {
                    //let schemaInfo = { ...infoBase };
                    fetch('https://api.growve.tools:8393/v2/schemas/postcards/brandInfo', { method: 'GET', mode: 'cors' })
                        .then(response => {
                            return response.json();
                        })
                        .then(json => {
                            infoBase.add({
                                validation: json.schema.validation,
                                fields: json.schema.fields,
                                schema: json.schema.schemaBase,
                                valid: {},
                                updated: false,
                                enableSubmit: false,
                                allInitial: true
                            })
                            return infoBase;
                        })
                    return infoBase;
                    //console.log('SchemaInfo before return');
                    //console.log(schemaInfo);
                    //return schemaInfo;

                })
                .then(infoTest => {
                    //let final = { ...schemaStart };
                    let final = infoTest.value();
                    //console.log(final);
                    let initValid = final.valid;
                    let initSchema = final.schema;
                    console.log(final);
                    console.log(final.fields);
                    for (const x of final.fields) {
                        let vf = Function(final.validation[x].arguments, final.validation[x].body);
                        initSchema.props.value = infoTest.form[x]
                        initValid.valid[x] = vf(infoTest.form[x]);
                    }
                    infoTest.update('valid', initValid);
                    infoTest.update('schema', initSchema);
                    return infoTest;

                })

            console.log('End');
            console.log(info);
            return info.value();
        case 'prep':
            //console.log('Prepping....');
            //info = { ...state };
            //fetch('https://api.growve.tools:8393/v2/schemas/postcards/brandInfo', { method: 'GET', mode: 'cors' })
            //    .then(response => {
            //        return response.json();
            //    })
            //    .then(json => {
            //        info.fields = json.fields;
            //        info.schema = json.schemaBase;
            //        info.validation = json.validation;
            //        info.valid = {};
            //        for (const x of fields) {
            //            let vf = Function(info.validation[x].arguments, info.validation[x].body);
            //            info.schema[x].props.value = state.form[x]
            //            info.valid[x] = vf(state.form[x]);
            //        }
            //        info.updated = false;
            //        info.enableSubmit = false;
            //        info.allInitial = true;
            //    })
            return state;
        case 'toggle-lock':
            info = { ...state };
            info.schema[action.field].props.locked = action.locked;
            return info;
        case 'set':
            info = { ...state };
            info.form[action.field] = action.value;
            for (const x of info.fields) {
                let vf = Function(info.validation[x].arguments, info.valdiation[x].body);
                info.schema[x].props.value = info.form[x];
                info.valid[x] = vf(info.form[x]);
            }
        default:
            return state;
            
            
    }
}

function BooleanInput(props) {
    return (
        <div className='col-span-1 justify-center p-3'>
            <div className='grid grid-cols-2'>
                <div className='justify-self-start'>
                    <label htmlFor={props.id} className='font-medium p-3 text-md text-gray-900'>
                        {props.label}
                    </label>
                    <div className='relatie flex gap-x-3 p-3'>
                        <input
                            id={props.id}
                            checked={props.value}
                            onChange={props.onChange}
                            disabled={props.locked}
                            type='checkbox'
                            className='h-4 w-4 disabled:pointer-events-none disabled:bg-gray-300 hover:cursor-pointer rounded border-gray-300 text-indigo-600'
                        />
                    </div>
                </div>
                <div className='justify-self-start flex'>
                    <button
                        className={props.locked ? 'rounded-lg p-3 bg-growveGreen text-white hover:bg-white hover:text-slate-500' : 'hidden'}
                        onClick={() => props.dispatch({ type: 'toggle-lock', field: props.field, value: false })}
                    >
                        <PencilIcon className='h-5 w-5 text-inherit' />
                    </button>
                    <button
                        className={props.locked ? 'hidden' : 'p-3 rounded-l-lg bg-growveGreen text-white hover:bg-white hover:text-slate-500'}
                        onClick={() => props.dispatch({ type: 'toggle-lock', field: props.field, value: true })}
                        disabled={!props.valid}
                    >
                        <CheckIcon className='h-5 w-5' />
                    </button>
                    <button
                        className={props.locked ? 'hidden' : 'rounded-r-lg p-3 bg-growveGreen text-white hover:bg-white hover:text-slate-500'}
                        onClick={() => props.dispatch({ type: 'reset', field: props.field })}
                    >
                        <XMarkIcon className='h-5 w-5' />
                    </button>
                </div>
            </div>
        </div>
    );
}
function TextInput(props) {
    return (
        <div className="col-span-1 justify-self-center w-full">
            <label htmlFor={props.id} className="ml-5 block text-start text-md font-medium leading-6 text-gray-900">
                {props.label}
            </label>
            <div className="m-2 w-full">
                <div className="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                        type={props.type}
                        id={props.id}
                        value={props.value}
                        onChange={props.onChange}
                        disabled={props.locked}
                        className="w-full block flex-1 rounded-l-lg border-0 disabled:bg-slate-600 disabled:text-white bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        autoFocus
                    />
                    <button
                        className={props.locked ? 'rounded-r-lg p-3 bg-growveGreen text-white hover:bg-white hover:text-slate-500' : 'hidden'}
                        onClick={() => props.dispatch({type: 'toggle-lock', field: props.field, value: false})}
                    >
                        <PencilIcon className='h-5 w-5 text-inherit' />
                    </button>
                    <button
                        className={props.locked ? 'hidden' : 'p-3 bg-growveGreen text-white hover:bg-white hover:text-slate-500'}
                        onClick={() => props.dispatch({ type: 'toggle-lock', field: props.field, value: true })}
                        disabled={!props.valid}
                    >
                        <CheckIcon className='h-5 w-5' />
                    </button>
                    <button
                        className={props.locked ? 'hidden':'rounded-r-lg p-3 bg-red-500 text-white hover:bg-white hover:text-red-400'}
                        onClick={() => props.dispatch({type: 'reset', field: props.field})}
                    >
                        <XMarkIcon className='h-5 w-5' />
                    </button>
                </div>
            </div>
        </div>
    )
}

function Form(props) {
    const [submitClicked, setSubmitClicked] = useState(false);
    function Field(props) {
        switch (props.form.schema[props.field].type) {
            case 'text':
                return <TextInput {...props.form.schema[props.field].props} dispatch={props.dispatch} valid={props.form.valid[props.field]} field={props.field} onChange={(e) => props.dispatch({ type: 'set', field: props.field, value: e.target.value })} />
            case 'bool':
                return <BooleanInput {...props.form.schema[props.field].props} dispatch={props.dispatch} valid={props.form.valid[props.field]} field={props.field} onChange={(e) => props.dispatch({type: 'set', field: props.field, value: e.target.checked}) } />
            default:
                return null;
        }
    }

    return (
        <>
            {props.form.fields.map(field => {
                return (
                    <Field key={props.form.schema[field].props.id} field={field} dispatch={props.dispatch} form={props.form} />
                )
            })}
            <div className='col-span-1 justify-self-center w-[6ch]'>
                <SubmitButton submitClicked={submitClicked} setSubmitClicked={setSubmitClicked} saveChanges={() => props.dispatch({ type: 'submit' })} disabled={!props.form.enableSubmit } />
                {/*<button*/}
                {/*    className={submitClicked ? 'bg-growveGreen disabled:bg-growveGreen/80 text-white p-3 flex rounded-lg justify-center col-span-3 animate-pulse' : 'bg-growveGreen disabled:bg-growveGreen/80 text-white p-3 flex rounded-lg justify-center col-span-3 '}*/}
                {/*    style={{ width: '9ch' }}*/}
                {/*    disabled={!props.form.enableSubmit || props.form.allInitial}*/}
                {/*    onClick={() => { setSubmitClicked(true); props.dispatch({ type: 'submit' }) }}*/}
                    
                {/*>*/}
                {/*    {submitClicked ? '...' : 'Submit'}*/}
                {/*</button>*/}
            </div>
        </>
    )
}

function BrandInfo(props) {
    const [form, dispatch] = useReducer(brandInfo, {});
    const [ready, setReady] = useState(false);
    const [resetPage, setResetPage] = useState(false);
    const [brand] = useState(props.brand)

    useEffect(() => {
        function initialize() {
            dispatch({ type: 'init', brand: props.brand });
        };
        initialize();
    }, []);

    useEffect(() => {
        function prepare() {
            if (Object.keys(form).includes('initial') && Object.keys(form).includes('form') && !Object.keys(form).includes('schema')) {
                dispatch({ type: 'prep' });
            } else {
                return;
            }

        };
        prepare();
    }, [form]);

    useEffect(() => {
        function checkReset() {
            console.log(`Updated: ${form.updated}`);
            if (form.updated === true) {
                setResetPage(true);
            } else {
                setResetPage(false);
            }
        };
        checkReset();
    }, [form, props]);

    useEffect(() => {
        function reset() {
            if (resetPage) {
                props.dispatch({ type: 'go-home' });
            } else {
                return
            }
        };
        reset()
    }, [props, resetPage]);

    if (ready) {
        return (
            <div className="mx-auto grid gap-3 max-w-7xl px-4 sm:px-6 lg:px-8 justify-center pb-24 pt-12">
                <h2 className='text-xl text-slate-600 font-bold p-6'>{form.initial.brand} Brand Settings:</h2>
                <Form form={form} dispatch={dispatch} />
            </div>
        )
    } else {
        return null;
    }
    
};

export { BrandInfo }