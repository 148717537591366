import { TextInput, LongTextInput, Toggle, Dropdown } from './FormFields';
import { surveyReducer } from '../Reducers/reducers';
import { useReducer, useState, useEffect} from 'react';

export default function HavasuRecallSurvey(props) {
    const keyMap = {
        "1": "Did you receive notification LAST YEAR (June 2023) that Havasu Nutrition voluntarily recalled Lot # 111-2023021 of Beet Root Powder?",
        "2": "Did you previously receive a shipment of that recalled product lot?",
        "3": "Do you still have any of the recalled Lot # 111-2023021 on hand?",
        "4": "Do you intend to return the product to Havasu Nutrition as requested?",
        "5": "Please explain what you intend to do with the product:",
        "6": "Have you or anyone in your household experienced illness or injury related to this product?",
        "7": "Please provide details:"
    }
    const [survey, dispatchSurvey] = useReducer(surveyReducer, {
        "1": '',
        "2": '',
        "3": '',
        "4": '',
        "5": '',
        "6": '',
        "7": ''
    });
    const [complete, setComplete] = useState(false);

    function checkSurvey() {
        var oneAndTwo;
        var three;
        var six;

        if (survey['1'] !== '' && survey['2'] === 'No') {
            oneAndTwo = true;
        } else if (survey['1'] !== '' && survey['2'] === 'Yes') {
            oneAndTwo = null;
        } else {
            oneAndTwo = false;
        };

        if (oneAndTwo === null && survey['3'] === 'Yes' && survey['4'] === 'Yes') {
            three = true;
        } else if (oneAndTwo === null && survey['3'] === 'Yes' && survey['4'] === 'No' && survey['5'] !== '') {
              three = true;
        } else {
            three = false;
        };
        if (survey['6'] === 'No') {
            six = true;
        } else if (survey['6'] === 'Yes' && survey['7'] !== '') {
            six = true;
        } else {
            six = false;
        };

        if (oneAndTwo === true) {
            setComplete(true);
            return;
        } else if (oneAndTwo === null && three === true && six === true) {
            setComplete(true);
            return;
        } else if(oneAndTwo === null && survey['3'] === 'No' && survey['6'] === 'No') {
            setComplete(true);
            return;
        } else {
            setComplete(false);
            return;
        }  
    };

    useEffect(() => {
        checkSurvey();
    }, [survey]);

    function dispatchString() {
        if (complete) {
            var newSurvey = {
                [keyMap['1']]: survey['1'],
                [keyMap['2']]: survey['2'],
                [keyMap['3']]: survey['3'] === '' ? null : survey['3'],
                [keyMap['4']]: survey['4'] === '' ? null : survey['4'],
                [keyMap['5']]: survey['5'] === '' ? null : survey['5'],
                [keyMap['6']]: survey['6'] === '' ? null : survey['6'],
                [keyMap['7']]: survey['7'] === '' ? null : survey['7']
            }
            props.dispatch({type: 'simpleUpdate', field: 'survey', value: JSON.stringify(newSurvey)});
            props.validate({type: 'explicitlySet', value: complete, field: 'survey'});
            return;
        } else {
            props.validate({type: 'explicitlySet', value: complete, field: 'survey'});
            return;
        }
        
    };

    useEffect(() => {
        dispatchString();
    }, [complete])



    return(
        <form className={props.hidden ? 'hidden' : '' }>
            <div className="space-y-12 ">
                <Dropdown
                    label="1. Did you receive notification LAST YEAR (June 2023) that Havasu Nutrition voluntarily recalled Lot # 111-2023021 of Beet Root Powder?"
                    onChange={(v) => dispatchSurvey({ type: 'set', field: '1', value: v })}
                    value={survey['1']}
                    options={['Yes', 'No']}
                />
                <Dropdown
                    label="2. Did you previously receive a shipment of that recalled product lot?"
                    onChange={(v) => dispatchSurvey({ type: 'set', field: '2', value: v })}
                    value={survey['2']}
                    options={['Yes', 'No']}
                />
                <div className={survey['2'] === 'Yes' ? 'space-y-12' : 'hidden'}>
                    <Dropdown
                        label="3. Do you still have any of the recalled Lot # 111-2023021 on hand?"
                        onChange={(v) => dispatchSurvey({ type: 'set', field: '3', value: v })}
                        value={survey['3']}
                        options={['Yes', 'No']}
                    />
                    <div className={survey['3'] === 'Yes' ? '' : 'hidden'}>
                        <Dropdown
                            label="3a. Do you intend to return the product to Havasu Nutrition as requested?"
                            onChange={(v) => dispatchSurvey({ type: 'set', field: '4', value: v })}
                            value={survey['4']}
                            options={['Yes', 'No']}
                        />
                    </div>
                    <div className={survey['4'] === 'No' ? '' : 'hidden'}>
                        <TextInput
                            label='3b. Please explain what you intend to do with the product:'
                            value={survey['5']}
                            onChange={(v) => dispatchSurvey({ type: 'set', field: '5', value: v })}
                        />
                    </div>
                    <Dropdown
                        label="4. Have you or anyone in your household experienced illness or injury related to this product?"
                        onChange={(v) => dispatchSurvey({ type: 'set', field: '6', value: v })}
                        value={survey['6']}
                        options={['Yes', 'No']}
                    />
                    <div className={survey['6'] === 'Yes' ? '' : 'hidden'}>
                        <TextInput
                            label='4a. Please provide details:'
                            value={survey['7']}
                            onChange={(v) => dispatchSurvey({ type: 'set', field: '7', value: v })}
                        />
                    </div>
                    
                </div>
            </div>
        </form>
    )
}