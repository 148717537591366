import { useState, useEffect } from 'react';
import { StarIcon as StarSolid } from '@heroicons/react/20/solid';
import { StarIcon as StarOutline, ClipboardIcon } from '@heroicons/react/24/outline'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PostReviewStepsWalmart from './ReviewStepsWalmart';
export default function PostReviewWalmart(props) {
    {/*
        Component to direct user to Amazon if review meets all requirements for redirect.
        
        Includes the user's chosen star rating, review text in a copy block, Amazon review URL button, and progress tracker for steps to post
        review on Amazon. 
    */}
    const [rating, setRating] = useState(props.rating);
    const [copied, setCopied] = useState(false);
    const [ratingText] = useState({
        0: 'How did we do?',
        1: 'Poor',
        2: 'Not good',
        3: 'Below average',
        4: 'Still needs work...',
        5: 'Excellent - 5 Stars!'
    })

    useEffect(() => {
        {/*
            Sets the rating to the users chosen star rating any time the rating prop changes.
            
            Rating will update every time this step is loaded.
        */}
        function changeRating() {
            setRating(props.rating);
        };
        changeRating();
    }, [props.rating]);
    return (
        <form className={props.hidden ? 'hidden' : ''}>
            <div className="space-y-12">
                <div >
                    <h2 className="text-lg font-semibold leading-7 text-gray-900">Your Review:</h2>
                    <CopyToClipboard
                        text={props.value}
                        onCopy={() => { setCopied(true); props.validate({ type: 'setCopied' }) }}
                    >
                        <div className='rounded-xl bg-cyan-50 py-1'>

                            <span className="text-xl pl-10 w-full flex text-start justify-start font-bold">"</span>
                            <p className="mt-1 w-full text-center flex px-10 justify-center text-sm leading-6 text-gray-600 font-bold italic">{props.value}</p>
                            <span className='text-xl pr-10 w-full flex text-end justify-end font-bold'>"</span>

                            <div className="flex gap-x-0.5 text-yellow-400 justify-center">
                                {rating >= 1 ? <StarSolid className="h-5 w-5 flex-none" aria-hidden="true" /> : <StarOutline className="h-5 w-5 flex-none" aria-hidden="true" />}
                                {rating >= 2 ? <StarSolid className="h-5 w-5 flex-none" aria-hidden="true" /> : <StarOutline className="h-5 w-5 flex-none" aria-hidden="true" />}
                                {rating >= 3 ? <StarSolid className="h-5 w-5 flex-none" aria-hidden="true" /> : <StarOutline className="h-5 w-5 flex-none" aria-hidden="true" />}
                                {rating >= 4 ? <StarSolid className="h-5 w-5 flex-none" aria-hidden="true" /> : <StarOutline className="h-5 w-5 flex-none" aria-hidden="true" />}
                                {rating === 5 ? <StarSolid className="h-5 w-5 flex-none" aria-hidden="true" /> : <StarOutline className="h-5 w-5 flex-none" aria-hidden="true" />}
                            </div>

                            <p className="mt-1 text-sm leading-6 text-gray-600 italic">{ratingText[rating]}</p>
                            <div className='w-full justify-end flex pr-10'>
                                <ClipboardIcon className={copied ? "h-5 w-5 text-green-400 focus:animate-spin mb-2" : "h-5 w-5 text-blue-400 focus:animate-spin mb-2"} />
                            </div>
                        </div>
                    </CopyToClipboard>
                    <PostReviewStepsWalmart copied={copied} form={props.form} validate={props.validate} />
                </div>
            </div>
        </form>
    )
};