/* eslint-disable no-lone-blocks */
import VerifyButton from './VerifyButton';
export default function OrderId(props) {
    {/* 
        Order ID form field for Postcard landing pages. Provides streamlined functionality to check for valid (and unused)
        Amazon Order IDs in Brand's Amazon SP data in Big Query.

        REQUIRED PROPS:
            hidden:
                Boolean value used to hide/show component.
            form:
                Object containing the landing page's fields for submission.
                Used to store entered value.
            dispatch:
                Reducer required for 'form' prop.
            validation:
                Object that houses format/value checks on form fields. 
            validate:
                Reducer required for validation object.
            verify:
                Function used to verify Amazon Order ID.
    */}
    return (
        <form className={props.hidden ? 'hidden' : ''}>
            <div className="space-y-12">
                <div>
                    <h2 className="text-lg font-semibold leading-7 text-gray-900">{[null, undefined].includes(props.pageInfo.orderIdText) ? 'Order Verification' : props.pageInfo.orderIdText}</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">{[null, undefined].includes(props.pageInfo.orderIdSubText) ? 'Enter the Amazon Order Id from your purchase.' : props.pageInfo.orderIdSubText}</p>

                    <div className="pt-5 pb-5 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
                        <div className="col-span-full">
                            <label htmlFor="amazonOrderId" className="block text-sm font-medium leading-6 text-gray-900 text-start">
                                {[null, undefined].includes(props.pageInfo.orderIdInputLabel) ? 'Amazon Order Id' : props.pageInfo.orderIdText}
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="amazonOrderId"
                                    id="amazonOrderId"
                                    value={props.form.orderId}
                                    onChange={(e) => { props.dispatch({ type: 'amazonOrderId', field: 'orderId', value: e.target.value, keyStroke: e.nativeEvent.inputType }); props.validate({ type: 'orderIdValidation', field: 'orderId', value: e.target.value }); } }
                                    
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="col-span-full">
                            <VerifyButton disabled={!props.validation.orderId} verify={props.verify} validation={props.validation} />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}