
export default function ContactInfo(props) {
    {/* 
        Form fields for contact info on Postcard landing pages (first name, last name, email).
        Aesthetic only - functionality provided by parent component.

        REQUIRED PROPS:
            hidden:
                Boolean value used to hide/show component.
            form:
                Object containing the landing page's fields for submission.
                Used to store entered value.
            dispatchForm:
                Reducer required for 'form' prop.
            validation:
                Object that houses format/value checks on form fields. 
            validate:
                Reducer required for validation object.
    */}
  
    return (
        <form className={props.hidden ? 'hidden' : 'w-full' }>
            <div className="space-y-12">
                <div>
                    <h2 className="text-lg font-semibold leading-7 text-gray-900">Contact Information</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">Use a valid email to receive updates about your offer.</p>

                    <div className="mt-5 mb-5 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 text-start">
                                First name
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    value={props.form.firstName}
                                    onChange={(e) => { props.dispatchForm({ type: 'simpleUpdate', field: 'firstName', value: e.target.value }); props.validate({type: 'nameValidation', field: 'firstName', value: e.target.value}) } }
                                    name="first-name"
                                    id="first-name"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900 text-start">
                                Last name
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    value={props.form.lastName}
                                    onChange={(e) => { props.dispatchForm({ type: 'simpleUpdate', field: 'lastName', value: e.target.value }); props.validate({type: 'nameValidation', field: 'lastName', value: e.target.value}) } }
                                    name="last-name"
                                    id="last-name"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-6">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 text-start">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    value={props.form.email}
                                    onChange={(e) => { props.dispatchForm({ type: 'simpleUpdate', field: 'email', value: e.target.value }); props.validate({type: 'emailValidation', field: 'email', value: e.target.value}) } }
                                    type="email"
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div> 
                        
                    </div>
                </div>
            </div>
        </form>
    )
}
