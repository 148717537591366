import { useState, useEffect, useReducer } from 'react';
import { Tooltip } from 'react-tooltip';
import { PlusIcon, ClipboardDocumentListIcon, ListBulletIcon, ArrowLeftIcon, HomeIcon, PencilIcon, ArchiveBoxIcon, CircleStackIcon, ChartBarIcon } from '@heroicons/react/24/solid';
import { Listbox } from '@headlessui/react';
import { BrandInfo } from './BackendForms';
import Logo from '../GrowveLogoWhite.png';
function Loading(props) {
    return (
        <div className='h-full w-full flex justify-center items-center'>
            <div
                className='animate-pulse h-42 w-42 bg-growveGreen rounded-full flex justify-center items-center'
            >
                <img src={Logo} className='p-6' alt='' />
            </div>
        </div>
    )
}
function Controls(props) {
    return (
        <div className='grid p-5 items-center h-1/6 grid-cols-12 content-center rounded-t-xl border bg-growveGreen border-slate-200 '>
            <div className='col-span-1 w-full h-12 rounded-tl-xl flex justify-center items-stretch'>
                {/* set home and back buttons to be set like () => someFunc(); then called with siteInfo.back() siteInfo.home()*/}
                <button
                    className='rounded-l-full bg-white hover:text-growveOrange hover:bg-white text-growveGreen p-3 w-full h-full'
                    data-tooltip-id='go-home'
                    data-tooltip-content='See All Brands'
                    data-tooltip-place='bottom-end'
                    data-tooltip-delay-show={100}
                    onClick={() => props.dispatch({ type: 'go-home' })}
                >
                    <HomeIcon className='h-6 w-6' />
                </button>
                <button
                    className='rounded-r-full bg-white hover:text-growveOrange hover:bg-white text-growveGreen p-3 w-full h-full'
                    onClick={() => props.dispatch({type: 'pull-data'}) }
                    data-tooltip-id='pull-data'
                    data-tooltip-content='Pull Postcard Data'
                    data-tooltip-place='bottom-end'
                    data-tooltip-delay-show={100}
                >
                    <CircleStackIcon className='h-6 w-6' />
                </button>
            </div>
            <div
                className='col-span-2 flex p-1 h-full justify-center'
                onClick={() => props.dispatch({type: 'go-home'}) }
            >
                <img className='hover:cursor-pointer max-h-20' src={props.siteInfo.selectedDomain !== null ? props.siteInfo.selectedDomain.logo : null} alt='' />
            </div>
            <Tooltip id='go-home' />
            <Tooltip id='pull-data' />
        </div>
    )
}
function DomainList(props) {
    return (
        <>
            {props.domains.map(domain => {
                return (
                    <div key={`${domain.primaryDomain}_outer`} className='p-6 grid grid-cols-12 content-center first:rounded-t-xl last:rounded-b-xl even:bg-slate-100 odd:bg-slate-200'>
                        <div className='col-span-3 flex justify-center content-center'>
                            <img className='h-12 self-center' src={domain.logo} alt='' />
                        </div>
                        <div className='content-center col-span-5 flex text-center justify-center grid grid-cols-1 py-3'>
                            <div className='text-lg font-bold'>
                                {domain.brand}
                            </div>
                            <div
                                className='text-md italic'
                                data-tooltip-id='alternate-domains'
                                data-tooltip-content={domain.alternateDomains.join(', ')}
                                data-tooltip-place='bottom'
                            >
                                <i>{`${domain.primaryDomain}.com${domain.alternateDomains.length > 0 ? ' +'+String(domain.alternateDomains.length) : ''}`}</i>
                            </div>
                        </div>
                        <div className='col-span-4 flex justify-center content-center'>
                            <div className='flex py-3'>
                                <button
                                    className='bg-white rounded-l-full text-growveGreen hover:bg-growveOrange hover:text-white p-3'
                                    data-tooltip-id='edit-pages'
                                    data-tooltip-content='View/Edit Existing Pages'
                                    data-tooltip-place='top'
                                    onClick={() => props.dispatch({type: 'view-pages', domain: domain})}
                                >
                                    <ListBulletIcon className='h-5 w-5 m-2' />
                                </button>
                                <button
                                    className='bg-white text-growveGreen hover:bg-growveOrange hover:text-white p-3'
                                    data-tooltip-id='new-page'
                                    data-tooltip-content='Create Page'
                                    data-tooltip-place='top'
                                >
                                    <PlusIcon className='h-5 w-5 m-2' />
                                </button>

                                <button
                                    className='bg-white rounded-r-full text-growveGreen hover:bg-growveOrange hover:text-white p-3'
                                    data-tooltip-id='brand-info'
                                    data-tooltip-content='View/Edit Brand Information'
                                    data-tooltip-place='top'
                                    onClick={() => props.dispatch({type: 'edit-brandInfo', domain: domain}) }
                                >
                                    <ClipboardDocumentListIcon className='h-5 w-5 m-2' />
                                </button>
                                {/*Add Performance Component below*/}
                                {/*<button*/}
                                {/*    className='bg-white ring-1 ring-inset rounded-r-md ring-gray-300 text-gray-400'*/}
                                {/*    data-tooltip-id='reporting'*/}
                                {/*    data-tooltip-content='View postcard performance'*/}
                                {/*    data-tooltip-place='top'*/}
                                {/*>*/}
                                {/*    <DocumentChartBarIcon className='h-5 w-5 m-2' />*/}
                                {/*</button>*/}
                            </div>
                        </div>
                        
                    </div>
                )
            })}
            <Tooltip id='alternate-domains' />
            <Tooltip id='edit-pages' />
            <Tooltip id='new-page' />
            {/*<Tooltip id='reporting' />*/}
            <Tooltip id='brand-info' />
        </>
    )
};

function PageTooltip(props) {
    if (props.pageInfo.pageType === 'GrowveReviews') {
        return (
            <div className='grid grid-cols-3 w-64 justify-content-center'>
                <a href={`https://${props.primaryDomain}.com/${props.path}`} target='_blank' rel='noopener noreferrer' className='font-bold col-span-3 flex justify-self-center text-blue-400'>.../{props.path}</a>
                <br />
                <div className='col-span-3 justify-center flex'>
                    <p className='font-semibold'>Objective: </p>&nbsp;<p className='italic'>Reviews</p>
                </div>
                <br />
                <p className='font-semibold italic col-span-3 justify-self-center mt-3'>Products:</p>
                {props.pageInfo.purchasedProduct.map(p => { return (<p className='col-span-3 text-xs p-1'>{p.title} {p.asin}</p>) })}
            </div>
        )
    } else if (props.pageInfo.pageType === 'Redirect') {
        return (
            <div>
                <a href={`https://${props.primaryDomain}.com/${props.path}`} target='_blank' rel='noopener noreferrer' className='font-bold pb-3 text-blue-400'>.../{props.path}</a>
                <div className='col-span-3 justify-center flex pb-3'>
                    <p className='font-semibold'>Objective: </p>&nbsp;<p className='italic'>Redirect</p>
                </div>
                <a href={props.pageInfo.url} target='_blank' rel='noopener noreferrer' className='text-blue-400 font-semibold italic text-xs'>{props.pageInfo.url}</a>
            </div>
        )
    } else if (props.pageInfo.pageType === 'GiftCardForPurchase') {
        return (
            <div className='grid grid-cols-3 w-64 justify-content-center'>
                <a href={`https://${props.primaryDomain}.com/${props.path}`} target='_blank' rel='noopener noreferrer' className='font-bold col-span-3 flex justify-self-center text-blue-400'>.../{props.path}</a>
                <br />
                <div className='col-span-3 justify-center flex'>
                    <p className='font-semibold'>Objective: </p>&nbsp;<p className='italic'>New Purchases</p>
                </div>
                <br />
                <p className='font-semibold italic col-span-3 justify-self-center flex mt-3'>Eligible Products:</p>
                {props.pageInfo.purchasedProduct.map(p => { return (<p className='col-span-3 text-xs p-1'>{p.title}: {p.asin}</p>) })}
                <br />
                <div className='col-span-3 justify-center flex'>
                    <p className='font-semibold italic'>Gift Card Amount: </p>&nbsp;<p className='italic'>{props.pageInfo.giftCardValue}</p>
                </div>
            </div>
        )
    } else {
        return <></>;
    }
}

function PageList(props) {
    const [pages, setPages] = useState(null);
    const [primaryDomain, setPrimaryDomain] = useState(null);

    useEffect(() => {
        function getPages() {
            if (pages === null || primaryDomain === null || props.siteInfo.selectedDomain.primaryDomain !== primaryDomain) {
                fetch(`https://api.growve.tools:8393/v2/pages/${props.siteInfo.selectedDomain.primaryDomain}`, {
                    method: 'GET',
                    mode: 'cors'
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(json => {
                        setPages(json.pages);
                        setPrimaryDomain(props.siteInfo.selectedDomain.primaryDomain);
                    })
            }
        };
        getPages()
    }, [props.siteInfo, primaryDomain, pages]);

    if (pages !== null) {
        return (
            <>
                {Object.keys(pages).map(page => {
                    return (
                        <div
                            key={`${props.siteInfo.selectedDomain.primaryDomain}/${page}/preview-page`}
                            className='flex w-full justify-center content-center even:bg-slate-200 odd:bg-slate-100 text-slate-600'>
                            <div data-tooltip-id={`${props.siteInfo.selectedDomain.primaryDomain}/${page}/preview-page`} data-tooltip-place='right' className='justify-center text-md flex items-center font-bold p-3 text-center align-middle'>
                                {`${props.siteInfo.selectedDomain.primaryDomain}.com/${page}` }
                            </div>
                            <div className='justify-center flex p-3'>
                                <button
                                    data-tooltip-content='Edit Page'
                                    data-tooltip-id='edit-page'
                                    className='rounded-l-full bg-white text-growveGreen hover:bg-growveOrange hover:text-white p-3'
                                    onClick={() => props.dispatch({ type: 'edit-page', path: page}) }
                                >
                                    <PencilIcon className='h-5 w-5' />
                                </button>
                                <button
                                    data-tooltip-content='View Page Performance'
                                    data-tooltip-id='page-performance'
                                    className='bg-white text-growveGreen hover:bg-growveOrange hover:text-white p-3'
                                >
                                    <ChartBarIcon className='h-5 w-5' />
                                </button>
                                <button
                                    data-tooltip-content='Archive Page'
                                    data-tooltip-id='archive-page'
                                    className='rounded-r-full bg-white text-growveGreen hover:bg-growveOrange hover:text-white p-3'
                                >
                                    <ArchiveBoxIcon className='h-5 w-5' />
                                </button>
                            </div>
                            <Tooltip clickable id={`${props.siteInfo.selectedDomain.primaryDomain}/${page}/preview-page`}>
                                <PageTooltip primaryDomain={primaryDomain} path={page} pageInfo={pages[page]} />
                                
                            </Tooltip>
                        </div>
                    )
                })}
                <Tooltip id='edit-page' />
                <Tooltip id='archive-page' />
                <Tooltip id='page-performance' />
                
                
            </>
        )
    } else {
        return null;
    }
    

};

function ComponentManager(props) {
    switch (props.siteInfo.action) {
        case 'view-domains':
            return (
                <>
                    <DomainList dispatch={props.dispatch} domains={props.siteInfo.showDomains === 'agency' ? props.siteInfo.agencyDomains : props.siteInfo.showDomains === 'core' ? props.siteInfo.coreDomains : props.siteInfo.domains} />
                </>
            );
        case 'view-pages':
            return (
                <>
                    <PageList dispatch={props.dispatch} siteInfo={props.siteInfo} />
                </>
            )
        case 'edit-brandInfo':
            return (
                <>
                    <BrandInfo brand={props.siteInfo.selectedDomain} domain={props.siteInfo.selectedDomain.primaryDomain} dispatch={props.dispatch} siteInfo={props.siteInfo} />
                </>
            );
        case 'loading':
            return (
                <>
                    <Loading />
                </>
            )
        default:
            return null;
    }
}
export { ComponentManager, Controls }