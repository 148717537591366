
export default function AddressInfo(props) {
    {/* 
        Address/Shipping form fields for Postcard landing pages.

        REQUIRED PROPS:
            hidden:
                Boolean value to hide/show component.
            form:
                Object containing the landing page's fields for submission.
                Used to store entered value.
            dispatch:
                Reducer required for 'form' prop.
            validation:
                Object that houses format/value checks on form fields. 
            validate:
                Reducer required for validation object.
    */}
    return (
        <form className={props.hidden ? 'hidden' : 'w-full' }>
            <div className="space-y-12">
                <div className="py-3">
                    <h2 className="text-lg font-semibold leading-7 text-gray-900">Shipping Is On Us</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">Where would you like us to ship your free bottle of {`${props.form.freeProduct.title}`}</p>

                    <div className="mt-5 mb-5 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
                        <div className="col-span-full">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-900 text-start">
                                Address 1
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="address1"
                                    value={props.form.address1}
                                    onChange={(e) => { props.dispatchForm({ type: 'simpleUpdate', field: 'address1', value: e.target.value }); props.validate({type: 'nameValidation', field: 'address1', value: e.target.value}) } }
                                    id="street-address"
                                    autoComplete="address-line1"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-900 text-start">
                                Address 2
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="address2"
                                    value={props.form.address2}
                                    onChange={(e) => { props.dispatchForm({ type: 'simpleUpdate', field: 'address2', value: e.target.value }) }}
                                    id="street-address"
                                    autoComplete="address-line2"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2 sm:col-start-1">
                            <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900 text-start">
                                City
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="city"
                                    value={props.form.city}
                                    onChange={(e) => { props.dispatchForm({ type: 'simpleUpdate', field: 'city', value: e.target.value }); props.validate({type: 'nameValidation', field: 'city', value: e.target.value}) } }
                                    id="city"
                                    autoComplete="address-level2"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900 text-start">
                                State
                            </label>
                            <div className="mt-2">
                                <select
                                    id="state"
                                    name="state"
                                    onChange={(e) => { props.dispatchForm({ type: 'simpleUpdate', field: 'state', value: e.target.value }); props.validate({ type: 'stateValidation', field: 'state', value: e.target.value }) }}
                                    value={props.form.state}
                                    autoComplete="address-level1"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                    <option value='...'>...</option>
                                    <option value='AL' id='selectAL'>AL</option>
                                    <option value='AK' id='selectAK'>AK</option>
                                    <option value='AZ' id='selectAZ'>AZ</option>
                                    <option value='AR' id='selectAR'>AR</option>
                                    <option value='CA' id='selectCA'>CA</option>
                                    <option value='CO' id='selectCO'>CO</option>
                                    <option value='CT' id='selectCT'>CT</option>
                                    <option value='DE' id='selectDE'>DE</option>
                                    <option value='DC' id='selectDC'>DC</option>
                                    <option value='FL' id='selectFL'>FL</option>
                                    <option value='GA' id='selectGA'>GA</option>
                                    <option value='HI' id='selectHI'>HI</option>
                                    <option value='ID' id='selectID'>ID</option>
                                    <option value='IL' id='selectIL'>IL</option>
                                    <option value='IN' id='selectIN'>IN</option>
                                    <option value='IA' id='selectIA'>IA</option>
                                    <option value='KS' id='selectKS'>KS</option>
                                    <option value='KY' id='selectKY'>KY</option>
                                    <option value='LA' id='selectLA'>LA</option>
                                    <option value='ME' id='selectME'>ME</option>
                                    <option value='MD' id='selectMD'>MD</option>
                                    <option value='MA' id='selectMA'>MA</option>
                                    <option value='MI' id='selectMI'>MI</option>
                                    <option value='MN' id='selectMN'>MN</option>
                                    <option value='MS' id='selectMS'>MS</option>
                                    <option value='MO' id='selectMO'>MO</option>
                                    <option value='MT' id='selectMT'>MT</option>
                                    <option value='NE' id='selectNE'>NE</option>
                                    <option value='NV' id='selectNV'>NV</option>
                                    <option value='NH' id='selectNH'>NH</option>
                                    <option value='NJ' id='selectNJ'>NJ</option>
                                    <option value='NM' id='selectNM'>NM</option>
                                    <option value='NY' id='selectNY'>NY</option>
                                    <option value='NC' id='selectNC'>NC</option>
                                    <option value='ND' id='selectND'>ND</option>
                                    <option value='OH' id='selectOH'>OH</option>
                                    <option value='OK' id='selectOK'>OK</option>
                                    <option value='OR' id='selectOR'>OR</option>
                                    <option value='PA' id='selectPA'>PA</option>
                                    <option value='RI' id='selectRI'>RI</option>
                                    <option value='SC' id='selectSC'>SC</option>
                                    <option value='SD' id='selectSD'>SD</option>
                                    <option value='TN' id='selectTN'>TN</option>
                                    <option value='TX' id='selectTX'>TX</option>
                                    <option value='UT' id='selectUT'>UT</option>
                                    <option value='VT' id='selectVT'>VT</option>
                                    <option value='VA' id='selectVA'>VA</option>
                                    <option value='WA' id='selectWA'>WA</option>
                                    <option value='WV' id='selectWV'>WV</option>
                                    <option value='WI' id='selectWI'>WI</option>
                                    <option value='WY' id='selectWY'>WY</option>
                                </select>
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900 text-start">
                                ZIP Code
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="postal-code"
                                    value={props.form.zipCode}
                                    onChange={(e) => { props.dispatchForm({ type: 'simpleUpdate', field: 'zipCode', value: e.target.value }); props.validate({type: 'zipValidation', field: 'zipCode', value: e.target.value}) } }
                                    id="postal-code"
                                    autoComplete="postal-code"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
