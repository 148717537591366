import { useState, useEffect, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import StepTracker from './StepTracker';
import OrderId from './OrderId';
import AddressInfo from './AddressInfo';
import ContactInfo from './ContactInfo';
import { stepReducer, formReducer, validationReducer, progressReducer } from '../Reducers/reducers';
import { isMobile } from 'react-device-detect';
import PostReview from './PostReview';
import FormNavButtons from './NavButtons';
import { getInitialSteps } from '../Utilities/postcardUtilities';
import { OrderIdInvalid, RetryButton, Complete, CheckYourEmail } from './Alerts';
import ProgressTracker from './ProgressTracker';

function Survey(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [brandInfo] = useState(props.brandInfo);
    const [pageInfo] = useState(props.pageInfo);
    const [KLID, setKLID] = useState(null);
    const [oid, setOID] = useState(null);
    const [fromPostcard] = useState(searchParams.get('oid') === null || searchParams.get('oid') === undefined ? false : true);
    const [returning] = useState(searchParams.get('klid') === null || searchParams.get('klid') === undefined ? false : true);

    function StepOne(props) {
        const [complete, setComplete] = useState(false);
        const [fromPostcard] = useState(props.fromPostcard);
        const [pageInfo] = useState(props.pageInfo);
        const [brandInfo] = useState(props.brandInfo);
        const [validationRun, setValidationRun] = useState(false);
        const [progress, dispatchProgress] = useReducer(progressReducer, {
            current: fromPostcard ? 'Contact Info' : 'Amazon Order Id',
            steps: ['Amazon Order Id', 'Contact Info', 'Check Your Email'],
            description: ['Verify your Amazon Order Id', 'Enter your contact information'],
            skipSteps: []
        })
        const [form, dispatchForm] = useReducer(formReducer, {
            firstName: '',
            lastName: '',
            email: '',
            domain: props.domain,
            path: props.path,
            orderId: (props.oid !== null && props.oid !== undefined ? props.oid : ''),
            asin: pageInfo.asin
        });
        const [validation, dispatchValidation] = useReducer(validationReducer, {
            firstName: false,
            lastName: false,
            email: false,
            orderId: false
        });

        function verifyOrderId() {
            var verifyURL;
            if (brandInfo.agency === 'false' || brandInfo.agency === false) {
                verifyURL = `https://api.growve.tools:8393/verifyOrderId`;
            } else if (brandInfo.agency === true || brandInfo.agency === 'true') {
                verifyURL = `https://api.growve.tools:8393/verifyOrderIdAgency`;
            };
            fetch(verifyURL, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({ orderId: form.orderId, ordersTable: brandInfo['ordersTable'] })
            })
                .then(response => {
                    return response.json();
                })
                .then(json => {
                    dispatchValidation({ type: 'orderIdValidation', field: 'orderId', value: form.orderId });
                    dispatchValidation({ type: 'verifyOrderId', value: json.orderVerified });
                })
        };

        function stepOneSubmit() {
            let data = new FormData();
            data.append('firstName', form.firstName);
            data.append('lastName', form.lastName);
            data.append('email', form.email);
            data.append('domain', form.domain);
            data.append('path', form.path.replace('/', ''));
            data.append('orderId', form.orderId);
            data.append('asin', form.asin);
            fetch('https://api.growve.tools:8393/surveyStepOne', {
                method: 'POST',
                mode: 'cors',
                body: data
            })
                .then(response => {
                    return response.json();
                })
                .then(json => {
                    setComplete(json.subscribed);
                    if (json.subscribed) {
                        dispatchProgress({ type: 'setCurrent', step: 'Check Your Email' });
                    }
                });
        };

        useEffect(() => {
            function verifyPostcardOrderId() {
                if (fromPostcard && validationRun === false) {
                    var verifyURL;
                    if (brandInfo.agency === 'false' || brandInfo.agency === false) {
                        verifyURL = `https://api.growve.tools:8393/verifyOrderId`;
                    } else if (brandInfo.agency === true || brandInfo.agency === 'true') {
                        verifyURL = `https://api.growve.tools:8393/verifyOrderIdAgency`;
                    };
                    fetch(verifyURL, {
                        method: 'POST',
                        mode: 'cors',
                        body: JSON.stringify({ orderId: form.orderId, ordersTable: brandInfo['ordersTable'] })
                    })
                        .then(response => {
                            return response.json();
                        })
                        .then(json => {

                            dispatchValidation({ type: 'orderIdValidation', field: 'orderId', value: form.orderId });
                            dispatchValidation({ type: 'verifyOrderId', value: json.orderVerified });
                            setValidationRun(true)
                        })
                };
            };
            verifyPostcardOrderId();
        }, [fromPostcard, brandInfo, form]);

        return (
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 self-center h-screen">
                <div className="mx-auto max-w-3xl rounded-3xl">
                    <div className='grid grid-cols-1 border-b pb-3 border-gray-100 bg-white/90 rounded-t-3xl'>
                        <div className='w-full flex justify-center pb-5 border-gray-200 bg-white/90 pt-5 rounded-t-3xl'>
                            <a href={brandInfo.logolink}>
                                <img className='h-12' src={`https://api.growve.tools:8393/getLogo/${props.domain}${props.path}`} alt="" />
                            </a>
                        </div>
                        <ProgressTracker
                            progress={progress}
                            hidden={complete}
                        />
                    </div>
                    <div className='px-10 bg-white pt-5'>
                        <OrderId
                            hidden={progress.current !== 'Amazon Order Id' || validation.orderIdValid === false || complete}
                            dispatch={dispatchForm}
                            validate={dispatchValidation}
                            form={form}
                            verify={verifyOrderId}
                            validation={validation}
                        />
                        <ContactInfo
                            hidden={progress.current !== 'Contact Info' || validation.orderIdValid === false || complete}
                            form={form}
                            dispatchForm={dispatchForm}
                            validate={dispatchValidation}
                            validation={validation}

                        />
                        <CheckYourEmail hidden={progress.current !== 'Check Your Email'} email={form.email} brandInfo={brandInfo} />
                        <FormNavButtons
                            hidden={validation.orderIdValid === false || complete}
                            next={['Amazon Order Id'].includes(progress.current) || (progress.current === 'Contact Info' && fromPostcard)}
                            nextBack={false}
                            submit={['Contact Info'].includes(progress.current)}
                            onSubmit={() => stepOneSubmit()}
                            dispatchProgress={dispatchProgress}
                            progress={progress}
                            isMobile={isMobile}
                            disableNext={!(validation.orderId && validation.orderIdValid)}
                            disableSubmit={(progress.current === 'Contact Info' && !(validation.firstName && validation.lastName && validation.email))}
                        />
                    </div>
                    <div className='pb-2 italic text-xs border-t border-gray-200 pt-2 px-4 text-gray-400 bg-white'>
                        {Object.keys(pageInfo).includes('disclaimerText') ? pageInfo.disclaimerText : `**Limit one free product per household. No additional purchase is necessary. Please allow 1-2 weeks for delivery. Offer not dependent on type of feedback provided. Offer only valid in the United States of America; void where prohibited. Offer valid while supplies last and subject to change or cancellation at any time. By completing this form you agree to receive future communication from ${brandInfo.brand}.`}
                    </div>
                    <div className='grid grid-cols-4 py-2.5 border-t border-gray-200 flex align-items-middle font-semibold italic h-fit text-xs bg-white/90 rounded-b-3xl'>
                        <a className='border-r border-gray-200 text-inherit flex justify-center' href={brandInfo.link1url}><span className='inline-block self-center text-center'>{brandInfo.link1text}</span></a>
                        <a className='border-r border-gray-200 text-inherit flex justify-center' href={brandInfo.link2url}><span className='inline-block self-center'>{brandInfo.link2text}</span></a>
                        <a className='border-r border-gray-200 align-middle text-inherit flex justify-center' href={brandInfo.link3url}><span className='inline-block self-center whitespace-nowrap'>{brandInfo.link3text}</span></a>
                        <a className='flex justify-center' href={brandInfo.link4url}><span className='inline-block self-center text-inherit whitespace-nowrap'>{brandInfo.link4text}</span></a>
                    </div>
                </div>
            </div>
        )

    };

    //function StepTwo(props) {
    //    const 
    //}

    if (!returning && pageInfo !== null && brandInfo !== null) {
        return (
            <StepOne
                fromPostcard={fromPostcard}
                pageInfo={pageInfo}
                brandInfo={brandInfo}
                domain={props.domain}
                path={props.path}
                oid={searchParams.get('oid')}
            />   
        )
    }

};
export default Survey