/* eslint-disable no-lone-blocks */
export default function FormNavButtons(props) {
    {/*
        Navigation button component. Can display in three configurations: next; next/back; back/submit.
        Includes functionality to disable all next buttons as well as submit. All functionality provided
        by parent component.

        REQUIRED PROPS:
            hidden:
                Boolean value used to display/hide component
            dispatchProgress:
                Reducer used to manage navigation. Uses standard reducer
                found at /src/Reducers/reducers.jsx.
            disableNext:
                Boolean value used to disable next button until conditions are met.
            disableSubmit:
                Boolean value used to enable/disable submit button until conditions are met.
        
        OPTIONAL PROPS:
            next, nextBack, submit:
                Boolean values managed by parent component which determine which button/set of buttons is displayed.
                Parent component should manage these props carefully, as only one should be 'true' at a time.
    */ }
    if (props.nextBack) {
        return (
            <span className={props.hidden ? 'hidden' : "isolate inline-flex rounded-md shadow-sm justify-center static bottom-0 my-5"}>
                <button
                    type="button"
                    onClick={() => {
                        props.dispatchProgress({ type: 'back' });
                    }}
                    className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-md font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 w-20 justify-center disabled:bg-gray-300 disabled:text-white disabled:pointer-events-none"
                >
                    Back
                </button>
                <button
                    type="button"
                    disabled={props.disableNext}
                    onClick={() => {
                        props.dispatchProgress({ type: 'next' });
                       }}
                    className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-md font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 w-20 justify-center disabled:bg-gray-300 disabled:text-white disabled:pointer-events-none"
                >
                    Next
                </button>
            </span>
        )
    } else if (props.next) {
        return (
            <span className={props.hidden ? 'hidden' : "isolate inline-flex rounded-md shadow-sm justify-self-center static bottom-0 my-5"}>
                <button
                    type="button"
                    disabled={props.disableNext}
                    onClick={() => {
                        props.dispatchProgress({ type: 'next' });
                        }}
                    className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-md font-semibold disabled:bg-gray-300 disabled:text-white disabled:pointer-events-none text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 w-40 text-center justify-center"
                >
                    Next
                </button>
            </span>
        )
    } else if (props.submit) {
        return (
            <span className={props.hidden ? 'hidden' : props.submitClicked ? "animate-pulse pointer-events-none isolate inline-flex rounded-md shadow-sm static bottom-0 my-5" : "isolate inline-flex rounded-md shadow-sm static bottom-0 my-5"}>
                <button
                    type="button"
                    onClick={() => {
                        props.dispatchProgress({ type: 'back' });
                        }}
                    className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-md font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 w-20 justify-center disabled:bg-gray-300 disabled:text-white disabled:pointer-events-none"
                >
                    Back
                </button>
                <button
                    type="button"
                    disabled={props.disableSubmit || props.submitClicked}
                    onClick={props.onSubmit}
                    className="relative inline-flex items-center rounded-r-md bg-white px-3 py-2 text-md font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 w-20 justify-center disabled:bg-gray-300 disabled:text-white disabled:pointer-events-none"
                >
                    Submit
                </button>
            </span>
        )
    }
    
};