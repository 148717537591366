/* eslint-disable no-lone-blocks */
import { useState, useEffect } from 'react';
function Expired(props) {
    {/*
        Sets default page when an offer has expired, redirecting user to the
        brand's shopify page.
    */}
    const [bgSet, setBgSet] = useState(false);
    useEffect(() => {
        function setBackground() {
            {/*
                Calls API using domain and path to get the background image for the page. Runs once on load.    
            */ }
            if (!bgSet) {
                fetch(`https://api.growve.tools:8393/getBackground/${props.domain}${props.path}`, {
                    method: 'GET',
                    mode: 'cors'
                })
                    .then(response => {
                        return response.blob();
                    })
                    .then(blob => {
                        let objectURL = URL.createObjectURL(blob);
                        console.log(objectURL);
                        let newClass = `App py-5 bg-no-repeat bg-cover min-h-screen align-items-middle flex`;
                        document.getElementById('App').setAttribute('class', newClass);
                        document.getElementById('App').style.backgroundImage = `url("${objectURL}")`
                        setBgSet(true);
                    })
            }

        };
        setBackground();
    }, [bgSet, props.domain, props.path])

    return (
        <div className="flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 self-center justify-self-center relative h-screen">
            <div className="mx-auto max-w-3xl w-screen rounded-3xl grid bg-white">
                <div className='grid grid-cols-1 border-b border-gray-100 justify-items-center'>
                    <div className='w-full flex justify-center self-center pb-5 border-gray-200 bg-white/90 pt-5 rounded-t-3xl'>
                        <a href={props.brandInfo.logolink}>
                            <img className='h-12' src={`https://api.growve.tools:8393/getLogo/${props.domain}${props.path}`} alt="" />
                        </a>
                    </div>
                </div>
                <h2 className='text-center text-4xl bold my-10'>Oops!</h2>
                <h3 className='text-center text-2xl mb-10'>This offer has expired, <br /> but you can check out our other products here:</h3>
                <a className='justify-self-center' href={props.brandInfo.logolink}>
                    <img className='h-12 mb-24 self-center' src={`https://api.growve.tools:8393/getLogo/${props.domain}${props.path}`} alt="" />
                </a>
                <div className='pb-2 italic text-xs border-t border-gray-200 pt-2 px-4 text-gray-400 bg-white gap-y-0'>
                    This offer has expired
                </div>
                <div className='grid grid-cols-4 py-2.5 border-t border-gray-200 flex align-items-middle font-semibold italic h-fit text-xs bg-white/90 rounded-b-3xl'>
                    <a className='border-r border-gray-200 text-inherit flex justify-center' href={props.brandInfo.link1url}><span className='inline-block self-center text-center'>{props.brandInfo.link1text}</span></a>
                    <a className='border-r border-gray-200 text-inherit flex justify-center' href={props.brandInfo.link2url}><span className='inline-block self-center'>{props.brandInfo.link2text}</span></a>
                    <a className='border-r border-gray-200 align-middle text-inherit flex justify-center' href={props.brandInfo.link3url}><span className='inline-block self-center whitespace-nowrap'>{props.brandInfo.link3text}</span></a>
                    <a className='flex justify-center' href={props.brandInfo.link4url}><span className='inline-block self-center text-inherit whitespace-nowrap'>{props.brandInfo.link4text}</span></a>
                </div>
            </div>
        </div>
    )
}
export default Expired