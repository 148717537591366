import logo from './logo.svg';
import './App.css';
import { useLocation } from 'react-router-dom';
import { useState, useEffect, useReducer } from 'react';
import ContactInfo from './Components/ContactInfo';
import AddressInfo from './Components/AddressInfo';
import OrderId from './Components/OrderId';
import StepTracker from './Components/StepTracker';
import FormNavButtons from './Components/NavButtons';
import WriteReview from './Components/WriteReview';
import { stepReducer } from './Reducers/reducers';
import GrowveReviewsSelectPurchase from './Components/GrowveReviewSelectPurchase';
import GrowveReviews from './Components/GrowveReviews';
import SiteInfoForm from './Components/SiteInfoForm';
import Survey from './Components/Survey';
import PostCard from './Components/PostCard';
import CookieManager from './Components/Cookies';
import SiteInfo from './Components/BackEnd'
import SurveyForm from './Components/SurveyPage';
function App() {
    //let location = useLocation();
    //const [domain] = useState(document.location.hostname);
    //const [path] = useState(location.pathname);
    //const [pageInfo, setPageInfo] = useState(null);
    //const [brandInfo, setBrandInfo] = useState(null);
    //useEffect(() => {
    //    function getPageInfo() {
    //        fetch(`https://api.growve.tools:8393/getPostcardPageInfo/${domain}${path}`, {
    //            method: 'GET',
    //            mode: 'cors'
    //        })
    //            .then(response => {
    //                return response.json()
    //            })
    //            .then(json => {
    //                setPageInfo(json.pageInfo);
    //                setBrandInfo(json.brandInfo);
    //            })
    //    };
    //    getPageInfo();
    //}, [domain, path]);
    if (['postcards.growve.tools'].includes(window.location.hostname)) {
        return (
            <div className="App bg-cyan-50 grid relative" id="App">
                <SiteInfo />
            </div>
        )
    } else {
        return (
            <div className="App py-5 bg-cyan-50 grid relative h-full" id="App">
                {/* <SurveyForm questions={
                    [
                        {
                          "type": "Text",
                          "question": "What is your name?",
                          "required": true
                        },
                        {
                          "type": "LongText",
                          "question": "Please tell us what you think:",
                          "required": false
                        },
                        {
                          "type": "Dropdown",
                          "question": "Choose your favorite:",
                          "options": [
                            "Dogs",
                            "Cats",
                            "Something Else"
                          ],
                          "required": true
                        },
                        {
                          "type": "Toggle",
                          "question": "Can I go back to sleep yet?",
                          "required": true
                        }
                      ]
                }
                feedChange={(v) => console.log(v)}
                feedValidation={(v) => console.log(v)}
                /> */}
                <PostCard />
                {/*<Survey brandInfo={brandInfo} pageInfo={pageInfo} domain={domain} path={path} />*/}
                {/*<SiteInfoForm />*/}
                {/*<GrowveReviewsSelectPurchase pageInfo={pageInfo} brandInfo={brandInfo} domain={domain} path={path} />*/}
                {/*<GrowveReviews pageInfo={pageInfo} brandInfo={brandInfo} domain={domain} path={path} />*/}
                {/*<CookieManager />*/}
            </div>
        );
    }
    //}
  
}

export default App;
