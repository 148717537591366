import { useState } from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';

function VerifyButton(props) {
    const [clicked, setClicked] = useState(false);

    function onClick() {
        setClicked(true);
        props.verify();
        setClicked(false);
    };

    return (
        <button
            type="button"
            onClick={onClick}
            disabled={props.disabled}
            className={props.validation.orderIdValid === false ? "w-32 rounded-md disabled:bg-red-300 disabled:text-gray-100 bg-red-600 px-3 py-3 mt-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 justify-content-center" : "justify-content-center w-32 rounded-md disabled:bg-green-300 disabled:text-gray-100 bg-green-600 px-3 py-2 mt-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"}
        >
            {!clicked && props.validation.orderIdValid === null ? "Verify" : clicked && props.validation.orderIdValid === null ? <span className='animate-pulse'>Verifying</span> : clicked && props.validation.orderIdValid === true ? <span className='flex justify-center'>Verified <CheckIcon className='w-4 h-4 ml-4' /></span> : "Verify"}
        </button>
    )
}

export default VerifyButton