import { CheckIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { useState, useEffect } from 'react';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PurchaseSteps(props) {
    const [purchaseSteps] = useState([
        { name: `Head to the ${props.brandInfo.brand} Amazon StoreFront`, description: 'Use the button below to head to Amazon', href: '#', status: 'current' },
        { name: `Purchase ${props.pageInfo.product}`, description: `Find ${props.pageInfo.product} on Amazon and make your purchase.`, href: '#', status: 'current' },
        { name: 'Copy your Amazon Order Id', description: 'Grab the Amazon Order Id from your purchase (e.g. 112-XXXXXXX-XXXXXXXX', href: '#', status: 'current' }
    ]);

    function setRedirected() {
        setInterval(() => {
            props.validate({ type: 'setRedirected' })
        }, 10000);
    }
    return (
        <div className={props.hidden ? 'hidden' : 'grid grid-cols-4 gap-y-4'}>
            <div className='pb-5 relative col-span-3 col-start-1 lg:col-span-3 lg:col-start-2 flex justify-start items-center'>
                <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="rounded-2xl bg-indigo-400 h-12 w-12 text-white font-bold align-middle flex justify-center items-center">1.</span>
                </span>
                <span className="ml-4 flex min-w-0 flex-col">
                    <span className="pointer-events-none text-md font-medium text-indigo-600 justify-center flex text-center">Head to the {props.brandInfo.brand} Storefront</span>
                </span>
            </div>
            <div className='pb-5 relative col-span-3 col-start-1 lg:col-span-3 lg:col-start-2 flex justify-start items-center'>
                <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="rounded-2xl bg-indigo-400 h-12 w-12 text-white font-bold align-middle flex justify-center items-center">2.</span>
                </span>
                <span className="ml-4 flex min-w-0 flex-col">
                    <span className="pointer-events-none text-md font-medium text-indigo-600 self-center">Purchase {props.pageInfo.product}</span>
                </span>
            </div>
            <div className='pb-5 relative col-span-3 col-start-1 lg:col-span-3 lg:col-start-2 flex justify-start items-center'>
                <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="rounded-2xl bg-indigo-400 h-12 w-12 text-white font-bold align-middle flex justify-center items-center">3.</span>
                </span>
                <span className="ml-4 flex min-w-0 flex-col">
                    <span className="pointer-events-none text-md font-medium text-indigo-600 self-center">Copy Your Amazon Order Id</span>
                </span>
            </div>
            <div className={props.hidden ? 'hidden' : 'col-span-4 lg:col-span-2 lg:col-start-2'}>
                <a href={props.pageInfo.amazonLink} onClick={() => setRedirected()} rel="noopener noreferrer" target="_blank" className='rounded-lg bg-blue-500 p-3 flex text-center justify-center items-center text-white'>Go To Amazon <ArrowTopRightOnSquareIcon className='h-4 w-4 ml-3 text-white' /></a>
            </div>
        </div>
    )
}
