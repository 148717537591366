export default function ProgressTracker(props) {
    return (
        <div className={props.hidden ? 'hidden' : 'drop-shadow-none border-none px-4 bg-white py-3' }>
            <h4 className="sr-only">Progress</h4>
            <div aria-hidden="true">
                <div className="mb-1 grid-cols-1 text-md font-medium text-gray-600 sm:grid drop-shadow-none">

                    <div className="text-center text-gray-500 drop-shadow-none">{props.progress.current}</div>
                    {/*<div className="text-center text-gray-800 text-sm font-light drop-shadow-none">{props.progress.description[props.progress.steps.indexOf(props.progress.current)]}</div>*/}
                </div>
                <div className="overflow-hidden rounded-full bg-gray-200 h-4">
                    <div className="h-4 rounded-full bg-gradient-to-r from-green-700 to-green-600" style={{ width: `${((props.progress.steps.indexOf(props.progress.current) + 1) / props.progress.steps.length)*100}%` }} />
                </div>
                
            </div>
        </div>
    )
}
