
import { useState, useEffect, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import OrderId from './OrderId';
import AddressInfo from './AddressInfo';
import ContactInfo from './ContactInfo';
import { formReducer, validationReducer, progressReducer } from '../Reducers/reducers';
import { isMobile } from 'react-device-detect';
import FormNavButtons from './NavButtons';
import { getInitialSteps } from '../Utilities/postcardUtilities';
import SurveyForm from './SurveyPage';
import { OrderIdInvalid, RetryButton, SurveyComplete } from './Alerts';
import ProgressTracker from './ProgressTracker';
import CookieManager from './Cookies';
import AgeVerification from './AgeVerification';
export default function SurveyAmazon(props) {
    {/*
        Standard landing page for Postcard review funnels. This page type is
        parsed when there is a single purchased product and a single option for
        the free product.

        REQUIRED PROPS:
            brandInfo:
                Brand info object used to pre-fill brand name, legal links,
                query Amazon data from Big Query, disable page titles for 
                sensitive content, etc. Provided by props.
            pageInfo:
                Page info object used to set up page for the offer called in
                the url. Page info object must be in the following form:
                {
                    pageType: String,
                    freeProduct: Array,
                    imageName: String,
                    purchasedProduct: Array,
                    minStar: Number,
                    expiration: DateTime,
                    disclaimerText: String,
                    flagText: Array
                }
    */ }
    const [searchParams, setSearchParams] = useSearchParams();
    {/* searchParams: State used to determine whether user scanned postcard or enterd URL manually. */ }
    const [brandInfo] = useState(props.brandInfo);
    const [showCookieBanner, setShowCookieBanner] = useState(false);
    const [pageInfo] = useState(props.pageInfo);
    const [starRatingMin] = useState(Object.keys(props.pageInfo).includes('minStar') ? props.pageInfo.minStar : 4);
    const [form, dispatchForm] = useReducer(formReducer, {
        firstName: '',
        email: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '...',
        zipCode: '',
        orderId: (searchParams.get('oid') !== null ? searchParams.get('oid') : ''),
        ordersTable: props.brandInfo.ordersTable,
        survey: '',
        marketplace: props.pageInfo.marketplace,
        surveyName: props.pageInfo.surveyName,
        brand: props.brandInfo.brand,
        brandCode: (props.brandInfo.brandCode ? props.brandInfo.brandCode : 'GMA'),
        purchasedProduct: props.pageInfo.purchasedProduct[0],
        freeProduct: props.pageInfo.freeProduct[0],
        domain: props.domain,
        path: props.path,
        submissionTime: '',
        agency: props.brandInfo.agency,
        showAgeVerification: props.pageInfo.showAgeVerification,
        ageVerification: 'Not Required',
        ageVerified: false
    });
    {/* form: Form object managed by 'formReducer' function. This object is what is sent to API upon submission. */}
    const [validation, dispatchValidation] = useReducer(validationReducer, {
        firstName: false,
        lastName: false,
        address1: false,
        city: false,
        state: false,
        zipCode: false,
        orderId: false,
        orderIdValid: null,
        email: false,
        survey: false
    });
    {/* validation: Validation object managed by 'validationReducer' function. 
        This object validates all necessary fields and is checked
        by 'progress' state object to allow fields to be viewed. */}
    const [fromPostcard] = useState(searchParams.get('oid') === null ? false : true);
    {/*  */}
    const physicalSteps = ['Amazon Order Id', 'Contact Info', 'Survey', 'Shipping Info', 'Complete'];
    const digitalSteps = ['Amazon Order Id', 'Survey', 'Contact Info', 'Complete'];
    const digitalCurrent = fromPostcard ? 'Survey' : 'Amazon Order Id';
    const physicalCurrent = fromPostcard ? 'Contact Info' : 'Amazon Order Id';
    const [progress, dispatchProgress] = useReducer(progressReducer, {
        current: props.pageInfo.freeProduct[0].digital ? digitalCurrent : physicalCurrent,
        steps: props.pageInfo.freeProduct[0].digital === true ? digitalSteps : physicalSteps,
        skipSteps: []
    })
    const [validationRun, setValidationRun] = useState(false);
    const [bgSet, setBgSet] = useState(false);
    const [displayCriteria, setDisplayCriteria] = useState(getDisplayCriteria());
    const [complete, setComplete] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [ageVerified, setAgeVerified] = useState(false);
    const [verifyStates] = useState(['NY']);

    useEffect(() => {
        function checkAgeVerification() {
            var nv = form.showAgeVerification ? true : false;
            var vs = verifyStates.includes(form.state) ? true : false;
            var av = form.ageVerified ? true : false;
            if (ageVerified === false) {
                if (nv && vs && !av && ageVerified === false) {
                    return setAgeVerified(false);
                } else if (!nv || (nv && !vs)) {
                    return setAgeVerified(true);
                } else if (nv && vs && av) {
                    return setAgeVerified(true)
                }
                
            } else {
                return;
            }   
        }
        checkAgeVerification();
    }, [form, ageVerified, verifyStates]);

    useEffect(() => {
        function setBackground() {
            {/*
                Calls API using domain and path to get the background image for the page. Runs once on load.    
            */ }
            if (!bgSet) {
                fetch(`https://api.growve.tools:8393/getBackground/${props.domain}/${props.pageInfo.imageName}`, {
                    method: 'GET',
                    mode: 'cors'
                })
                    .then(response => {
                        return response.blob();
                    })
                    .then(blob => {
                        let objectURL = URL.createObjectURL(blob);
                        let newClass = `App py-5 bg-no-repeat bg-cover min-h-screen align-items-middle flex`;
                        document.getElementById('App').setAttribute('class', newClass);
                        document.getElementById('App').style.backgroundImage = `url("${objectURL}")`
                        setBgSet(true);
                    })
            }
            
        };
        setBackground();
    }, [bgSet, props.domain, props.path])
    useEffect(() => {
        {/*
           If Order ID is provided in query string, checks to see if it's in the BigQuery table provided for that brand.
           If Order ID is found, allows survey to proceed, otherwise blocks progression until a valid, unused Order Id is provided.
        */ }
        function verifyPostcardOrderId() {
            if (fromPostcard && validationRun === false) {
                var verifyURL = `https://api.growve.tools:8393/v2/verify/amazon/orderId`;
                fetch(verifyURL, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify({ orderId: form.orderId, ordersTable: brandInfo['ordersTable'], asin: form.purchasedProduct.asin, agency: form.agency })
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(json => {
                        
                        dispatchValidation({ type: 'orderIdValidation', field: 'orderId', value: form.orderId });
                        dispatchValidation({ type: 'verifyOrderId', value: json.orderVerified });
                        setValidationRun(true)
                    })
            };
        };
        verifyPostcardOrderId();
    }, [fromPostcard, brandInfo, form]);

    function verifyOrderId() {
        {/*
           If Order ID is provided in query string, checks to see if it's in the BigQuery table provided for that brand.
           If Order ID is found, allows survey to proceed, otherwise blocks progression until a valid, unused Order Id is provided.
           This method is used when no Order ID is passed by query parameters, and manually entering Order ID and clicking 'Verify'.
        */ }
        var verifyURL = `https://api.growve.tools:8393/v2/verify/amazon/orderId`;
        
        fetch(verifyURL, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ orderId: form.orderId, ordersTable: brandInfo['ordersTable'], asin: form.purchasedProduct.asin, agency: form.agency })
        })
            .then(response => {
                return response.json();
            })
            .then(json => {
                dispatchValidation({ type: 'orderIdValidation', field: 'orderId', value: form.orderId });
                dispatchValidation({ type: 'verifyOrderId', value: json.orderVerified });
                if (json.orderVerified !== false) {
                    dispatchProgress({ type: 'setCurrent', step: progress.steps[1] })
                }
            })
    };

    

    function submitForm() {
        setSubmitClicked(true)
        const formData = {...form, submissionTime: new Date(Date.now()).toISOString()}
        fetch('https://api.growve.tools:8393/v2/surveySubmission', {method: 'POST', mode: 'cors', body: JSON.stringify(formData)})
            .then(response => {
                return response.json()
            })
            .then(json => {
                checkCompletion(json.success);
            });

    };
    function checkCompletion(boolVal) {
        if (boolVal) {
            setComplete(true);
            dispatchProgress({ type: 'setCurrent', step: 'Complete' })
        } else {
            setComplete(false);
        }
    };
    

    useEffect(() => {
        {/*
            For GrowveReviews component, one free product is offered per campaign. This method checks on load
            for whether the offering is a digital product (typically giftcard), or a physical product, and updates
            the 'form' state object accordingly (removing address, and setting the proper fields to be sent to OrderDesk).
        */ }
        function checkDigitalProduct() {
            if (form.freeProduct !== '...' && form.freeProduct.digital === true && form.address1 !== 'None') {
                dispatchForm({ type: 'simpleUpdate', field: 'address1', value: 'None' });
                dispatchForm({ type: 'simpleUpdate', field: 'address2', value: 'None' });
                dispatchForm({ type: 'simpleUpdate', field: 'city', value: 'None' });
                dispatchForm({ type: 'simpleUpdate', field: 'state', value: 'None' });
                dispatchForm({ type: 'simpleUpdate', field: 'zipCode', value: 'None' });
                dispatchValidation({ type: 'explicitlySet', field: 'address1', value: true });
                dispatchValidation({ type: 'explicitlySet', field: 'city', value: true });
                dispatchValidation({ type: 'explicitlySet', field: 'state', value: true });
                dispatchValidation({ type: 'explicitlySet', field: 'zipCode', value: true });
                dispatchProgress({ type: 'skipStep', step: 'Address Info' });
            }
        };
        checkDigitalProduct();
    }, [form]);
        {/*
            Verifies reviewText and starRating to determine whether to send customer to Amazon.

            If starRating is lower than the survey's minimum star rating (pageInfo.minstar), or any of the terms in 
            pageInfo.flagText (array) are found in reviewText, steps are reconfigured to go straight to shipping information
            instead of sending customer to Amazon.

            Runs every time 'form' state object is updated.
        */}
        
// ['Amazon Order Id', 'Contact Info', 'Survey', 'Shipping Info', 'Complete']
    function getDisplayCriteria() {
        {/*
            Method that determines which set of progress buttons (next, next/back/ back/submit) to
            display for the current step.
        */}
        var nextB;
        if (!fromPostcard) {
            nextB = ['Amazon Order Id'];
        } else {
            nextB = ['Contact Info'];
        };
        var nextBackB;
        if (fromPostcard && props.pageInfo.freeProduct[0].digital) {
            nextBackB = ['Survey'];
        } else if (fromPostcard && !props.pageInfo.freeProduct[0].digital) {
            nextBackB = ['Survey'];
        } else if (!fromPostcard && props.pageInfo.freeProduct[0].digital) {
            nextBackB = ['Contact Info', 'Survey'];
        } else if (!fromPostcard && !props.pageInfo.freeProduct[0].digital) {
            nextBackB = ['Contact Info', 'Survey'];
        };
        var subB;
        if (props.pageInfo.freeProduct[0].digital) {
            subB = ['Survey'];
        } else {
            subB = ['Shipping Info'];
        }; 
        return {
            next: nextB,
            nextBack: nextBackB,
            submit: subB
        };
    }

    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 self-center justify-self-center relative">
            <div className="mx-auto max-w-3xl rounded-3xl">
                <div className='grid grid-cols-1 border-b border-gray-100'>
                    <div className='w-full flex justify-center pb-5 border-gray-200 bg-white/90 pt-5 rounded-t-3xl'>
                        <a href={brandInfo.logolink}>
                            <img className='h-12' src={`https://api.growve.tools:8393/getLogo/${props.domain}${props.path}`} alt="" />
                        </a>
                    </div>
                    <ProgressTracker
                        progress={progress}
                        hidden={complete}
                    />
                </div>
                <div className='px-10 bg-white pt-5 flex flex-col justify-center items-center w-full'>
                    <OrderId
                        hidden={progress.current !== 'Amazon Order Id' || validation.orderIdValid === false || complete}
                        dispatch={dispatchForm}
                        validate={dispatchValidation}
                        form={form}
                        verify={verifyOrderId}
                        validation={validation}
                        pageInfo={pageInfo}
                    />
                    <ContactInfo
                        hidden={progress.current !== 'Contact Info' || validation.orderIdValid === false || complete}
                        form={form}
                        dispatchForm={dispatchForm}
                        validate={dispatchValidation}
                        validation={validation}
                    />
                    <SurveyForm
                        hidden={progress.current !== 'Survey' || validation.orderIdValid === false || complete}
                        questions={pageInfo.questions}
                        form={form}
                        feedChange={dispatchForm}
                        feedValidation={dispatchValidation}
                    />
                    <AddressInfo
                        isMobile={isMobile}
                        hidden={progress.current !== 'Shipping Info' || validation.orderIdValid === false || complete}
                        form={form}
                        dispatchForm={dispatchForm}
                        validate={dispatchValidation}
                        validation={validation}
                    />
                    <OrderIdInvalid orderId={form.orderId} hidden={validation.orderIdValid !== false} />
                    <RetryButton hidden={validation.orderIdValid !== false} dispatch={dispatchForm} dispatchSteps={dispatchProgress} validate={dispatchValidation} />
                    <SurveyComplete hidden={progress.current !== 'Complete'} email={form.email} />
                    <FormNavButtons
                        hidden={validation.orderIdValid === false || complete}
                        next={displayCriteria.next.includes(progress.current)}
                        submitClicked={submitClicked}
                        nextBack={displayCriteria.nextBack.includes(progress.current)}
                        submit={displayCriteria.submit.includes(progress.current)}
                        
                        onSubmit={() => {submitForm()}}
                        dispatchProgress={dispatchProgress}
                        progress={progress}
                        isMobile={isMobile}
                        disableNext={progress.current === 'Amazon Order Id' ? (!validation.orderId || validation.orderIdValid === null) : progress.current === 'Contact Info' ? !(validation.firstName && validation.lastName && validation.email) : progress.current === 'Survey' ? !(validation.survey) : false}
                        disableSubmit={!(validation.orderId && validation.orderIdValid && validation.firstName && validation.lastName && validation.address1 && validation.city && validation.zipCode && validation.email && validation.survey) || submitClicked }
                    />
                    <AgeVerification
                        form={form}
                        dispatch={dispatchForm}
                    />
                </div>
                <div className='pb-2 italic text-xs border-t border-gray-200 pt-2 px-4 text-gray-400 bg-white'>
                    {pageInfo.disclaimerText !== null ? pageInfo.disclaimerText : `**Limit one free product per household. No additional purchase is necessary. Please allow 1-2 weeks for delivery. Offer not dependent on type of feedback provided. Offer only valid in the United States of America; void where prohibited. Offer valid while supplies last and subject to change or cancellation at any time. By completing this form you agree to receive future communication from ${brandInfo.brand}.`}
                </div>
                <div className='grid grid-cols-5 py-2.5 border-t border-gray-200 flex align-items-middle font-semibold italic h-fit text-xs bg-white/90 rounded-b-3xl'>
                    <a className='border-r border-gray-200 text-inherit flex justify-center' href={brandInfo.link1url}><span className='inline-block self-center text-center'>{brandInfo.link1text}</span></a>
                    <a className='border-r border-gray-200 text-inherit flex justify-center' href={brandInfo.link2url}><span className='inline-block self-center'>{brandInfo.link2text}</span></a>
                    <a className='border-r border-gray-200 align-middle text-inherit flex justify-center' href={brandInfo.link3url}><span className='inline-block self-center whitespace-nowrap'>{brandInfo.link3text}</span></a>
                    <a className='flex justify-center' href={brandInfo.link4url}><span className='inline-block self-center text-inherit whitespace-nowrap'>{brandInfo.link4text}</span></a>
                    <button className='border-r border-gray-200 align middle justify-center text-inherit' onClick={() => setShowCookieBanner(prev => !prev)}>Cookies</button>
                </div>
            </div>
            <CookieManager brandInfo={brandInfo} showBanner={showCookieBanner} setShowBanner={setShowCookieBanner} />
        </div>
    )
    
    
};