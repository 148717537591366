import { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';

export default function AgeVerification(props) {
    const [verifyStates] = useState(['NY']);
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [verified, setVerified] = useState(false);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (year !== '' && month !== '' && day !== '') {
            checkAge(`${month} ${day}, ${year}`);
        } else {
            return;
        }
    }, [year, month, day]);
    function checkAge(dateStr) {
        const today = new Date();
        const targetYear = today.getFullYear() - 18;
        const targetDay = today.getDate();
        const targetMonth = today.getMonth()+1;
        const targetDateString = `${targetMonth}-${targetDay}-${targetYear}`
        const targetDate = new Date(targetDateString);
        const dob = new Date(dateStr);
        if (targetDate.getTime() >= dob.getTime()) {
            setVerified(true);
        } else {
            setVerified(false);
        }
    };

    function years() {
        var years = [];
        for (var i = 1900; i <= 2024; i++) {
            years.push(String(i));
        };
        years.sort((a, b) => b - a);
        return years;
    };

    function days() {
        var daysMap = {
            January: 31,
            February: 29,
            March: 31,
            April: 30,
            May: 31,
            June: 30,
            July: 31,
            August: 31,
            September: 30,
            October: 31,
            November: 30,
            December: 31
        };
        var days = [];
        for (var i = 1; i <= daysMap[month]; i++) {
            days.push(String(i));
        };
        return days;
    };
    
    function checkIfOpen() {
        if (props.form.freeProduct !== '...' && props.form.freeProduct.showAgeVerification === true && props.form.ageVerified === false && verifyStates.includes(props.form.state) && open === false) {
            setOpen(true);
            return;
        } else if (props.form.ageVerified === true && open === true) {
            setOpen(false);
            return;
        }else {
            return;
        }      
    };

    useEffect(() => {
        checkIfOpen();
    }, [props.form, open])



    return(
        <Dialog
            open={open}
            onClose={() => console.log('Closed')}
            as='div'
        >
            <div className="fixed inset-0 flex justify-center items-center overflow-hidden">
                <Dialog.Panel className='flex justify-center items-center flex-col max-w-lg space-y-4 border bg-white p-12 rounded-md'>
                    <Dialog.Title className='font-bold'>
                        Age Verification
                    </Dialog.Title>
                    <Dialog.Description>Please verify your age to receive your free product. Must be 18 years or older.</Dialog.Description>
                    <div className='flex'>
                        <select
                            id="verification-month"
                            name="verification-month"
                            onChange={(e) => setMonth(e.target.value)}
                            value={month}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            <option disabled value=''>Month</option>
                            {months.map((monthValue) => (
                                <option key={`verification-month-${monthValue}`} value={monthValue}>
                                    {monthValue}
                                </option>
                            ))}
                        </select>
                        <select
                            id="verification-day"
                            name="verification-day"
                            onChange={(e) => setDay(e.target.value)}
                            value={day}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            <option disabled value=''>Day</option>
                            {days().map((dayValue) => (
                                <option key={`verification-day-${dayValue}`} value={dayValue}>
                                    {dayValue}
                                </option>
                            ))}
                        </select>
                        <select
                            id="verification-year"
                            name="verification-year"
                            onChange={(e) => setYear(e.target.value)}
                            value={year}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            <option disabled value=''>Year</option>
                            {years().map((yearValue) => (
                                <option key={`verification-year-${yearValue}`} value={yearValue}>
                                    {yearValue}
                                </option>
                            ))}
                        </select>
                        {/* <Listbox as='div' className='w-full h-full' value={month} onChange={setMonth}>
                            <Listbox.Button>{month}</Listbox.Button>
                            <Listbox.Options>
                                <Listbox.Option disabled value=''>...</Listbox.Option>
                                {months.map((monthValue) => (
                                    <Listbox.Option key={monthValue} value={monthValue}>
                                        {monthValue}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Listbox>
                        <Listbox value={day} onChange={setDay}>
                            <Listbox.Button>{day}</Listbox.Button>
                            <Listbox.Options>
                                <Listbox.Option disabled value=''>...</Listbox.Option>
                                {days().map((dayValue) => (
                                    <Listbox.Option key={dayValue} value={dayValue}>
                                        {dayValue}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Listbox>
                        <Listbox value={year} onChange={setYear}>
                            <Listbox.Button>{year}</Listbox.Button>
                            <Listbox.Options>
                                <Listbox.Option disabled value=''>...</Listbox.Option>
                                {years().map((yearValue) => (
                                    <Listbox.Option key={yearValue} value={yearValue}>
                                        {yearValue}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Listbox> */}
                    </div>
                    <button
                        className='rounded-xl bg-green-400 p-3 text-white rounded-2xl disabled:bg-green-400/60 disabled:text-gray-500 disabled:cursor-not-allowed'
                        onClick={() => props.dispatch({ 
                            type: 'uploadAgeVerification', 
                            value: {
                                dateOfBirth: `${month} ${day}, ${year}`,
                                orderId: props.form.orderId,
                                page: `https://${props.form.domain}.com/${props.form.path}`,
                                ageVerifiedTime: Date.now()
                            }})}
                            disabled={!verified}
                    >
                        Verify
                    </button>
                </Dialog.Panel>
            </div>
        </Dialog>
    )
}