import { useState, useEffect, useReducer } from 'react';
import { useDebounce } from 'use-debounce';
import { ComponentManager, Controls } from './BackendComponents';

function siteInfoReducer(state, action) {
    if (state.loading === true) {
        return state;
    } else {
        switch (action.type) {
            case 'init-domains':
                var agencyDomains = state.domains.filter(x => x.agency);
                var coreDomains = state.domains.filter(x => !x.agency);
                console.log(state);
                return {
                    ...state,
                    showDomains: 'all',
                    agencyDomains: agencyDomains,
                    coreDomains: coreDomains,
                    action: 'view-domains'
                };

            case 'get-logos':
                let initialDomains = state.domains;
                for (const domain of initialDomains) {
                    fetch(`https://api.growve.tools:8393/landingPageImages/${domain.directory}/logo.png`, {
                        method: 'GET',
                        mode: 'cors'
                    })
                        .then(response => {
                            return response.blob();
                        })
                        .then(blob => {
                            var oURL = URL.createObjectURL(blob);
                            domain.logo = oURL
                        })
                }
                console.log('get-logos');
                console.log(initialDomains);
                return {
                    ...state,
                    domains: initialDomains,
                    showDomains: 'all',
                    domainsReady: true
                };
            case 'get-domains':
                let initState = { ...state, domainsReady: false }
                fetch('https://api.growve.tools:8393/v2/domains', {
                    method: 'GET',
                    mode: 'cors'
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(json => {
                        initState.domains = json.domains;
                        initState.initialized = true;
                    })
                console.log('get-domains');
                console.log(initState);
                return initState
            case 'view-pages':
                return {
                    ...state,
                    selectedDomain: action.domain,
                    action: 'view-pages'
                };
            case 'view-pages-return':
                return {
                    ...state,
                    action: 'view-pages',
                    selectedPath: null
                };
            case 'go-home':
                return {
                    ...state,
                    selectedDomain: null,
                    selectedPath: null,
                    action: 'view-domains',
                    show: 'all'
                };
            case 'edit-page':
                return {
                    ...state,
                    selectedPath: action.path,
                    action: 'edit-page'
                }
            case 'pull-data':
                return {
                    ...state,
                    action: 'pull-data'
                }
            case 'edit-brandInfo':
                return {
                    ...state,
                    selectedDomain: action.domain,
                    action: 'edit-brandInfo'
                }

            default:
                return state;
        }
    }
    
}

var defaultInfo = {
    domains: [],
    search: '',
    showDomains: [],
    domainsReady: false,
    selectedDomain: null,
    domainIndex: null,
    props: null,
    selectedPath: null,
    action: 'loading',
    show: 'all',
    initialized: false,
    loading: false

}
export default function SiteInfo(props) {
    const [siteInfo, dispatch] = useReducer(siteInfoReducer, defaultInfo);
    const [loaded, setLoaded] = useState(false);
    const [ready, setReady] = useState(false);
    

    function checkStatus() {
        if (!ready && !loaded && siteInfo.domains.length === 0) {
            console.log("Loading");
            dispatch({ type: 'get-domains' });
            setLoaded(true);
            return;
        } else if (loaded && !ready && siteInfo.domains.length > 0 && !Object.keys(siteInfo).includes('coreDomains')) {
            console.log('Prepping');
            dispatch({ type: 'init-domains' });
            return;
        } else if (loaded && !ready && siteInfo.domains.length > 0 && Object.keys(siteInfo).includes('coreDomains')) {
            console.log('Prepping 2');
            dispatch({ type: 'get-logos' });
            setReady(true);
            return;
        } else {
            console.log('Prepping 3');
            dispatch({ type: '' });
            return;
        }
    };

    //function chaos() {
    //    if (siteInfo.domainsReady === false) {
    //        setLoaded(prev => !prev);
    //    } else {
    //        return;
    //    }
    //}

    //useEffect(() => {
    //    chaos();
    //}, [siteInfo])
    useEffect(() => {
        checkStatus();
    }, [ready, loaded])
    //useEffect(() => {
    //    function checkStatus() {
    //        if (ready && loaded) {
    //            console.log('Done');
    //            return;
    //        } else if (loaded && !ready && siteInfo.domains.length > 0) {
    //            dispatch({ type: 'init-domains', setReady: setReady });
    //            console.log('Domains Initialized');
    //            setReady(true);
    //        } else if (!loaded && !ready) {
    //            dispatch({ type: 'get-domains', setLoaded: setLoaded });
    //            console.log('Got Domains');
    //            setLoaded(true);
    //        }
    //    };
    //    checkStatus();
    //}, [loaded, ready]);

    

    
    return (
        <div className='h-screen'>
            <Controls dispatch={dispatch} siteInfo={siteInfo} />
            <div className='overflow-y-scroll h-5/6'>
                <ComponentManager siteInfo={siteInfo} dispatch={dispatch} />
            </div>
        </div>

    )
    
    
    
    
}