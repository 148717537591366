/* eslint-disable no-lone-blocks */
import { useState, useEffect } from 'react';
import GrowveReviews from './GrowveReviews';
import GrowveReviewsSelectPurchase from './GrowveReviewSelectPurchase';
import GrowveReviewsSelectFree from './GrowveReviewSelectFree';
import Expired from './Expired';
import SurveyAmazon from './SurveyAmazon';
import Survey from './Survey';
import { useLocation } from 'react-router-dom';
import GrowveReviewsSelectBoth from './GrowveReviewSelectBoth';
import GiftCardForPurchase from './GiftCardForPurchase';
import WalmartReviews from './WalmartReviews';
import WalmartReviewsSelectFree from './WalmartReviewsSelectFree';
import RecallSurvey from './RecallSurvey';
import CookieManager from './Cookies';
import { useSearchParams } from 'react-router-dom';
import GrowveRedemption from './GrowveRedemption';
function PostCard(props) {
    {/*
                     
    */}
    let location = useLocation();
    const [searchParams] = useSearchParams();
    let asinParam = searchParams.get('asin')
    const [domain] = useState(['postcards-v2.web.app', 'localhost'].includes(document.location.hostname) ? new URLSearchParams(window.location.search).get('domain') : document.location.hostname.replace('.com', '').replace('www.', ''));
    const [path] = useState(['postcards-v2.web.app', 'localhost'].includes(document.location.hostname) ? new URLSearchParams(window.location.search).get('path') : location.pathname);
    const [brandInfo, setBrandInfo] = useState(null);
    const [pageInfo, setPageInfo] = useState(null);
    const [pageInfoReady, setPageInfoReady] = useState(false);
    const [expired, setExpired] = useState(null);
    const [selectPurchase, setSelectPurchase] = useState(null);
    const [selectFree, setSelectFree] = useState(null);
    const [scanLogged, setScanLogged] = useState(false);

    useEffect(() => {
        function logScan() {
            if (!scanLogged && brandInfo !== null && pageInfo !== null && searchParams.get('oid') !== null && searchParams.get('oid') !== undefined) {
                var url = `https://api.growve.tools:8393/${brandInfo.agency ? 'qrScanAgency' : 'qrScan'}`;
                var body = {
                    'order_id': searchParams.get('oid'),
                    'domain': domain,
                    'page_path': path,
                    'scan_time': Date.now()
                }
                fetch(url, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify(body)
                })
                    .then(response => { setScanLogged(true) })
            } else {
                return;
            }
        };
        logScan();
    }, [brandInfo, pageInfo, scanLogged, searchParams, domain, path])
    useEffect(() => {
        function getDataLayer() {
            if (pageInfo !== null && !Array.isArray(pageInfo) && Object.keys(pageInfo).includes('purchasedProduct') && brandInfo !== null) {
                if (pageInfo.purchasedProduct.length > 1) {
                    var productAsin = Array.from(pageInfo.purchasedProduct, x => x.asin).join(', ');
                    var productName = Array.from(pageInfo.purchasedProduct, x => `${x.brand} ${x.title}`).join(', ')
                } else {
                    var productAsin = pageInfo.purchasedProduct[0].asin;
                    var productName = `${pageInfo.purchasedProduct[0].brand} ${pageInfo.purchasedProduct[0].title}`;
                }
                var analyticsInfo = {
                    'pageType': pageInfo.pageType,
                    'asin': productAsin,
                    'domain': domain,
                    'pagePath': path,
                    'product': productName,
                    'brand': brandInfo.brand
                };
                window.dataLayer.push(analyticsInfo);
            }
        };
        getDataLayer();
    }, [pageInfo, brandInfo]);
    useEffect(() => {
        function getFaviconAndSetText() {
            var favicon = document.getElementById('favicon_tag')
            favicon.href = `https://api.growve.tools:8393/getFavicon/${domain}${path}`
            var title = document.getElementById('title_tag');
            if (brandInfo !== null && brandInfo !== undefined && Object.keys(brandInfo).includes('brand')) {
                title.innerText = brandInfo.brand
            }
        }
        getFaviconAndSetText();
    }, [domain, path, brandInfo]);
    useEffect(() => {
        function getPageInfo() {
            var pathText = path.length > 1 ? path : '/none';
            fetch(`https://api.growve.tools:8393/getNewPostcardPageInfo/${domain}${pathText}`, {
                method: 'GET',
                mode: 'cors'
            })
                .then(response => {
                    return response.json()
                })
                .then(json => {
                    var jsonResponse = json;
                    if (jsonResponse.pageInfo === null || (Array.isArray(jsonResponse.pageInfo) && jsonResponse.pageInfo[0] === null)) {
                        window.location.replace(jsonResponse.brandInfo.logolink);
                    } else if (jsonResponse.pageInfo.pageType === 'Redirect') {
                        if (json.pageInfo.url.includes('?')) {
                            window.location.replace(`${jsonResponse.pageInfo.url}${window.location.search.replace('?', '&')}`)
                        } else {
                            window.location.replace(`${jsonResponse.pageInfo.url}${window.location.search}`);
                        };
                    } else {
                        setPageInfo(jsonResponse.pageInfo);
                    }
                    
                    setBrandInfo(jsonResponse.brandInfo);
                })
        };
        getPageInfo();
    }, [domain, path]);

    useEffect(() => {
        function updatePurchasedProduct() {
            if (![null, undefined].includes(pageInfo) && ![null, undefined].includes(asinParam) && asinParam.length === 10 && !pageInfoReady) {
                var purchased = pageInfo.purchasedProduct.filter(x => x.asin === asinParam)[0];
                setPageInfo({
                    ...pageInfo,
                    purchasedProduct: [purchased]
                });
                setPageInfoReady(true);
            } else if (![null, undefined].includes(pageInfo) && !pageInfoReady) {
                setPageInfoReady(true);
            } else {
                return;
            }
        }
        updatePurchasedProduct();
    }, [pageInfo])

    useEffect(() => {
        function checkSelect() {
            if (pageInfo !== null && Object.keys(pageInfo).includes('purchasedProduct') && pageInfo.purchasedProduct.length > 1) {
                setSelectPurchase(true);
            } else {
                setSelectPurchase(false);
            };
            if (Object.keys(pageInfo).includes('freeProduct') && pageInfo.freeProduct.length > 1) {
                setSelectFree(true);
            } else {
                setSelectFree(false);
            }
        };
        function checkExpirationDate() {
            if (pageInfo !== null && pageInfo.expiration !== null) {
                var now = Date.now();
                var exp = new Date(pageInfo.expiration).getTime();
                if (now > exp) {
                    setExpired(true);
                } else {
                    setExpired(false);
                }
            } else {
                setExpired(false);
            }
        };
        if (pageInfoReady) {
            checkExpirationDate();
            checkSelect();
        }
    }, [pageInfo, pageInfoReady]);

    

    if (!pageInfoReady) {
        return null;
    } else if (pageInfo === null) {
        window.location.replace(brandInfo.logolink);
    } else if (pageInfo.pageType === 'RecallSurvey') {
        return(
            <>
                <RecallSurvey pageInfo={pageInfo} brandInfo={brandInfo} domain={domain} path={path} />
            </>
        )
    } else if (expired) {
        return (
            <>
                <Expired domain={domain} path={path} pageInfo={pageInfo} brandInfo={brandInfo} />
            </>
        )
    } else if (!expired && pageInfo.pageType === 'CustomSurvey') {
        return(
            <>
                <SurveyAmazon domain={domain} path={path} pageInfo={pageInfo} brandInfo={brandInfo} />
            </>
        )
    } else if (!expired && pageInfo.pageType === 'GrowveRedemption') {
        return(
            <GrowveRedemption pageInfo={pageInfo} brandInfo={brandInfo} domain={domain} path={path} />
        )
    } else if (!expired && pageInfo.pageType === 'GrowveReviews' && selectFree === false && selectPurchase === false) {
        return (
            <>
                <GrowveReviews pageInfo={pageInfo} brandInfo={brandInfo} domain={domain} path={path} />
            </>
        );
    } else if (!expired && pageInfo.pageType === 'GrowveReviews' && selectFree === false && selectPurchase === true) {
        return (
            <>
                <GrowveReviewsSelectPurchase pageInfo={pageInfo} brandInfo={brandInfo} domain={domain} path={path} />
            </>
        )
    } else if (!expired && pageInfo.pageType === 'GrowveReviews' && selectFree === true && selectPurchase === false) {
        return (
            <>
                <GrowveReviewsSelectFree pageInfo={pageInfo} brandInfo={brandInfo} domain={domain} path={path} />
            </>
        )
    } else if (!expired && pageInfo.pageType === 'GrowveReviews' && selectFree === true && selectPurchase === true) {
        return (
            <>
                <GrowveReviewsSelectBoth pageInfo={pageInfo} brandInfo={brandInfo} domain={domain} path={path} />
            </>
        )
    } else if (!expired && pageInfo.pageType === 'Survey') {
        return (<Survey brandInfo={brandInfo} pageInfo={pageInfo} domain={domain} path={path} />);
    } else if (!expired && pageInfo.pageType === 'GiftCardForPurchase') {
        return (
            <>
                <GiftCardForPurchase brandInfo={brandInfo} pageInfo={pageInfo} domain={domain} path={path} />
            </>
        )
    } else if (!expired && pageInfo.pageType === 'WalmartReviews' && selectFree === false) {
        return (
            <>
                <WalmartReviews pageInfo={pageInfo} brandInfo={brandInfo} domain={domain} path={path} />
            </>
        )
    } else if (!expired && pageInfo.pageType === 'WalmartReviews' && selectFree === true) {
        return (
            <>
                <WalmartReviewsSelectFree pageInfo={pageInfo} brandInfo={brandInfo} domain={domain} path={path} />
            </>
        )
    }


};

export default PostCard;