import { useState, useEffect, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import StepTracker from './StepTracker';
import WalmartOrderId from './WalmartOrderId';
import AddressInfo from './AddressInfo';
import ContactInfo from './ContactInfo';
import Review from './WriteReview';
import { stepReducer, formReducer, validationReducer, progressReducer } from '../Reducers/reducers';
import { isMobile } from 'react-device-detect';
import PostReviewWalmart from './PostReviewWalmart';
import FormNavButtons from './NavButtons';
import SelectFreeProduct from './SelectFreeProduct';
import { getInitialStepsPurchase } from '../Utilities/postcardUtilities';
import { WalmartOrderIdInvalid, RetryButton, Complete } from './Alerts';
import ProgressTracker from './ProgressTracker';
import CookieManager from './Cookies';
import AgeVerification from './AgeVerification';
function GrowveReviewsSelectFree(props) {
    const [searchParams] = useSearchParams();
    const [brandInfo] = useState(props.brandInfo);
    const [pageInfo] = useState(props.pageInfo);
    const [showCookieBanner, setShowCookieBanner] = useState(false);
    const [starRatingMin] = useState(Object.keys(props.pageInfo).includes('minStar') ? props.pageInfo.minStar : 4);
    const [form, dispatchForm] = useReducer(formReducer, {
        firstName: '',
        email: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '...',
        zipCode: '',
        orderId: (searchParams.get('oid') !== null ? searchParams.get('oid') : ''),
        ordersTable: props.brandInfo.ordersTable,
        starRating: 0,
        brand: props.brandInfo.brand,
        reviewText: '',
        purchasedProduct: '...',
        domain: props.domain,
        path: props.path,
        submissionTime: '',
        agency: props.brandInfo.agency,
        freeProduct: '...',
        walmart: true,
        showAgeVerification: props.pageInfo.showAgeVerification,
        ageVerification: 'Not Required',
        ageVerified: false
    });
    const [validation, dispatchValidation] = useReducer(validationReducer, {
        firstName: false,
        lastName: false,
        address1: false,
        city: false,
        state: false,
        zipCode: false,
        orderId: false,
        orderIdValid: null,
        email: false,
        starRating: false,
        reviewText: false,
        copied: false,
        redirected: false
    });
    const [fromPostcard] = useState(searchParams.get('oid') === null ? false : true);
    const [progress, dispatchProgress] = useReducer(progressReducer, {
        current: fromPostcard ? 'Choose Free Product' : 'Amazon Order Id',
        steps: ['Walmart Order #', 'Choose Free Product', 'Feedback', 'Post Review', 'Contact Info', 'Shipping Info', 'Complete'],
        skipSteps: []
    })
    const [validationRun, setValidationRun] = useState(false);
    const [bgSet, setBgSet] = useState(false);
    const [complete, setComplete] = useState(false);
    const [displayCriteria, setDisplayCriteria] = useState(getDisplayCriteria());
    const [submitClicked, setSubmitClicked] = useState(false);
    const [ageVerified, setAgeVerified] = useState(false);
    const [verifyStates] = useState(['NY']);

    useEffect(() => {
        function checkAgeVerification() {
            var nv = form.showAgeVerification ? true : false;
            var vs = verifyStates.includes(form.state) ? true : false;
            var av = form.ageVerified ? true : false;
            if (ageVerified === false) {
                if (nv && vs && !av && ageVerified === false) {
                    return setAgeVerified(false);
                } else if (!nv || (nv && !vs)) {
                    return setAgeVerified(true);
                } else if (nv && vs && av) {
                    return setAgeVerified(true)
                }
                
            } else {
                return;
            }   
        }
        checkAgeVerification();
    }, [form, ageVerified, verifyStates])

    useEffect(() => {
        function updateDisplayCriteria() {
            setDisplayCriteria(getDisplayCriteria());
        }
        updateDisplayCriteria();
    }, [form.freeProduct]);


    useEffect(() => {
        {/*
            For GrowveReviews component, one free product is offered per campaign. This method checks on load
            for whether the offering is a digital product (typically giftcard), or a physical product, and updates
            the 'form' state object accordingly (removing address, and setting the proper fields to be sent to OrderDesk).
        */ }
        function checkDigitalProduct() {
            if (form.freeProduct !== '...' && form.freeProduct.digital === true) {
                dispatchForm({ type: 'simpleUpdate', field: 'address1', value: 'None' });
                dispatchForm({ type: 'simpleUpdate', field: 'address2', value: 'None' });
                dispatchForm({ type: 'simpleUpdate', field: 'city', value: 'None' });
                dispatchForm({ type: 'simpleUpdate', field: 'state', value: 'None' });
                dispatchForm({ type: 'simpleUpdate', field: 'zipCode', value: 'None' });
                dispatchValidation({ type: 'explicitlySet', field: 'address1', value: true });
                dispatchValidation({ type: 'explicitlySet', field: 'city', value: true });
                dispatchValidation({ type: 'explicitlySet', field: 'state', value: true });
                dispatchValidation({ type: 'explicitlySet', field: 'zipCode', value: true });
                dispatchProgress({ type: 'skipStep', step: 'Address Info' });
            }
        };
        checkDigitalProduct();
    }, [form]);

    function getDisplayCriteria() {
        var nextB;
        if (!fromPostcard) {
            nextB = ['Amazon Order Id'];
        } else {
            nextB = ['Choose Free Product'];
        };
        var nextBackB;
        if (fromPostcard) {
            if (form.freeProduct !== '...' && form.freeProduct.digital) {
                nextBackB = ['Post Review'];
            } else {
                nextBackB = ['Feedback', 'Post Review', 'Contact Info'];
            }

        } else if (!fromPostcard) {
            if (form.freeProduct !== '...' && form.freeProduct.digital) {
                nextBackB = ['Choose Free Product', 'Post Review', 'Feedback'];
            } else {
                nextBackB = ['Choose Free Product', 'Contact Info', 'Feedback', 'Post Review'];
            }
        } else if (!fromPostcard && !props.pageInfo.freeProduct[0].digital) {

        };
        var subB;
        if (props.pageInfo.freeProduct[0].digital) {
            subB = ['Contact Info'];
        } else {
            subB = ['Shipping Info'];
        };
        return {
            next: nextB,
            nextBack: nextBackB,
            submit: subB
        };
    }
    useEffect(() => {
        function updateSubmitButton() {
            if (form.freeProduct.digital && displayCriteria.submit[0] === 'Shipping Info') {
                setDisplayCriteria({
                    ...displayCriteria,
                    nextBack: displayCriteria.nextBack.filter(x => x !== 'Contact Info'),
                    submit: ['Contact Info']
                })
            } else if (!form.freeProduct.digital && displayCriteria.submit[0] === 'Contact Info') {
                setDisplayCriteria({
                    ...displayCriteria,
                    nextBack: [...displayCriteria.nextBack, 'Contact Info'],
                    submit: ['Shipping Info']
                })
            } else {
                return
            }
        }
        updateSubmitButton();
    }, [form, displayCriteria]);
    useEffect(() => {
        function setBackground() {
            if (!bgSet) {
                fetch(`https://api.growve.tools:8393/getBackground/${props.domain}/${props.pageInfo.imageName}`, {
                    method: 'GET',
                    mode: 'cors'
                })
                    .then(response => {
                        return response.blob();
                    })
                    .then(blob => {
                        let objectURL = URL.createObjectURL(blob);
                        let newClass = `App py-5 bg-no-repeat bg-cover min-h-screen align-items-middle flex`;
                        document.getElementById('App').setAttribute('class', newClass);
                        document.getElementById('App').style.backgroundImage = `url("${objectURL}")`
                        setBgSet(true);
                    })
            }

        };
        setBackground();
    }, [bgSet, props.domain, props.path])
    useEffect(() => {
        function verifyPostcardOrderId() {
            {/*
           If Order ID is provided in query string, checks to see if it's in the BigQuery table provided for that brand.
           If Order ID is found, allows survey to proceed, otherwise blocks progression until a valid, unused Order Id is provided.
           This method is used when no Order ID is passed by query parameters, and manually entering Order ID and clicking 'Verify'.
        */ }
            if (fromPostcard && validationRun === false) {
                var verifyURL = 'https://api.growve.tools:8393/v2/verify/walmart';
                fetch(verifyURL, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify({ orderId: form.orderId, agency: brandInfo['agency'] })
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(json => {
                        dispatchForm({ type: 'simpleUpdate', field: 'purchasedProduct', value: json })
                        dispatchValidation({ type: 'orderIdValidation', field: 'orderId', value: form.orderId });
                        dispatchValidation({ type: 'verifyOrderId', value: json.verified });
                        if (json.verified !== false) {
                            dispatchProgress({ type: 'setCurrent', step: progress.steps[1] })
                        }
                        setValidationRun(true);
                    })
            }

        };
        verifyPostcardOrderId();
    }, [fromPostcard, brandInfo, form]);

    function verifyOrderId() {
        {/*
           If Order ID is provided in query string, checks to see if it's in the BigQuery table provided for that brand.
           If Order ID is found, allows survey to proceed, otherwise blocks progression until a valid, unused Order Id is provided.
           This method is used when no Order ID is passed by query parameters, and manually entering Order ID and clicking 'Verify'.
        */ }
        var verifyURL = 'https://api.growve.tools:8393/v2/verify/walmart';
        fetch(verifyURL, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ orderId: form.orderId, agency: brandInfo['agency'] })
        })
            .then(response => {
                return response.json();
            })
            .then(json => {
                dispatchForm({ type: 'simpleUpdate', field: 'purchasedProduct', value: json })
                dispatchValidation({ type: 'orderIdValidation', field: 'orderId', value: form.orderId });
                dispatchValidation({ type: 'verifyOrderId', value: json.verified });
                if (json.verified !== false) {
                    dispatchProgress({ type: 'setCurrent', step: progress.steps[1] })
                }
            })
    };

    function submitForm() {
        setSubmitClicked(true);
        dispatchForm({ type: 'reviewSubmission', checkComplete: checkCompletion });
    };
    function checkCompletion(boolVal) {
        if (boolVal) {
            setComplete(true);
            dispatchProgress({ type: 'setCurrent', step: 'Complete' })
        } else {
            setComplete(false);
        }
    };


    useEffect(() => {
        function removePostReview() {
            let flagged = false;
            let text = form.reviewText;
            for (const y of pageInfo.flagText) {
                let term = y.toLowerCase();
                if (text.includes(term)) {
                    flagged = true;
                    break
                } else {
                    continue;
                }
            }
            if ((flagged && !progress.skipSteps.includes('Post Review')) || (form.starRating !== 0 && form.starRating < starRatingMin && !progress.skipSteps.includes('Post Review'))) {
                dispatchProgress({ type: 'skipStep', step: 'Post Review' });
            } else if (!flagged && form.starRating !== 0 && form.starRating >= starRatingMin && progress.skipSteps.includes('Post Review')) {
                dispatchProgress({ type: 'doNotSkip', step: 'Post Review' });
            }
        };
        removePostReview();
    }, [form]);

    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 self-center">
            <div className="mx-auto max-w-3xl rounded-3xl border border-gray-200">
                <div className='grid grid-cols-1 border-b border-gray-100'>
                    <div className='w-full flex justify-center pb-5 border-gray-200 bg-white/90 pt-5 rounded-t-3xl'>
                        <a href={brandInfo.logolink}>
                            <img className='h-12' src={`https://api.growve.tools:8393/getLogo/${props.domain}${props.path}`} alt="" />
                        </a>
                    </div>
                    <ProgressTracker
                        progress={progress}
                        hidden={complete}
                    />
                </div>
                <div className='px-10 bg-white pt-5 flex flex-col justify-center items-center w-full'>
                    <WalmartOrderId
                        hidden={progress.current !== 'Amazon Order Id' || validation.orderIdValid === false || complete}
                        dispatch={dispatchForm}
                        validate={dispatchValidation}
                        form={form}
                        verify={verifyOrderId}
                        validation={validation}
                        pageInfo={pageInfo}
                    />
                    <ContactInfo
                        hidden={progress.current !== 'Contact Info' || validation.orderIdValid === false || complete}
                        form={form}
                        dispatchForm={dispatchForm}
                        validate={dispatchValidation}
                        validation={validation}
                        pageInfo={pageInfo}
                    />
                    <Review
                        hidden={progress.current !== 'Feedback' || validation.orderIdValid === false || complete}
                        rating={form.starRating}
                        value={form.reviewText}
                        form={form}
                        dispatch={dispatchForm}
                        validate={dispatchValidation}
                        pageInfo={pageInfo}
                        validation={validation}
                    />
                    <PostReviewWalmart
                        hidden={progress.current !== 'Post Review' || validation.orderIdValid === false || complete}
                        rating={form.starRating}
                        value={form.reviewText}
                        form={form}
                        dispatch={dispatchForm}
                        validate={dispatchValidation}
                        validation={validation}
                        pageInfo={pageInfo}
                    />
                    <SelectFreeProduct
                        form={form}
                        hidden={progress.current !== 'Choose Free Product' || validation.orderIdValid === false || complete}
                        pageInfo={pageInfo}
                        dispatchForm={dispatchForm}
                    />
                    <AddressInfo
                        isMobile={isMobile}
                        hidden={progress.current !== 'Shipping Info' || validation.orderIdValid === false || complete}
                        form={form}
                        dispatchForm={dispatchForm}
                        validate={dispatchValidation}
                        validation={validation}
                        pageInfo={pageInfo}
                    />
                    <WalmartOrderIdInvalid orderId={form.orderId} hidden={validation.orderIdValid !== false} pageInfo={pageInfo} />
                    <RetryButton hidden={validation.orderIdValid !== false} dispatchSteps={dispatchProgress} dispatch={dispatchForm} validate={dispatchValidation} />
                    <Complete hidden={progress.current !== 'Complete'} email={form.email} pageInfo={pageInfo} />
                    <FormNavButtons
                        hidden={validation.orderIdValid === false || complete}
                        next={displayCriteria.next.includes(progress.current)}
                        nextBack={displayCriteria.nextBack.includes(progress.current)}
                        submit={displayCriteria.submit.includes(progress.current)}
                        onSubmit={submitForm}
                        submitClicked={submitClicked}
                        dispatchProgress={dispatchProgress}
                        progress={progress}

                        isMobile={isMobile}
                        disableNext={progress.current === 'Amazon Order Id' ? (!validation.orderId || validation.orderIdValid === null) : progress.current === 'Contact Info' ? !(validation.firstName && validation.lastName && validation.email) : progress.current === 'Choose Product' ? form.asin === '' : progress.current === 'Feedback' ? !(validation.reviewText && validation.starRating) : progress.current === 'Post Review' ? !(validation.copied && validation.redirected) : false}
                        disableSubmit={!(validation.orderId && validation.orderIdValid && validation.firstName && validation.lastName && validation.address1 && validation.city && validation.zipCode && validation.email && validation.starRating && validation.reviewText)}
                    />
                    <AgeVerification
                        form={form}
                        dispatch={dispatchForm}
                    />
                </div>
                <div className='pb-2 italic text-xs border-t border-gray-200 pt-2 px-4 text-gray-400 bg-white'>
                    {pageInfo.disclaimerText !== null ? pageInfo.disclaimerText : `**Limit one free product per household. No additional purchase is necessary. Please allow 1-2 weeks for delivery. Offer not dependent on type of feedback provided. Offer only valid in the United States of America; void where prohibited. Offer valid while supplies last and subject to change or cancellation at any time. By completing this form you agree to receive future communication from ${brandInfo.brand}.`}
                </div>
                <div className='grid grid-cols-5 py-2.5 border-t border-gray-200 flex align-items-middle font-semibold italic h-fit text-xs bg-white/90 rounded-b-3xl'>
                    <a className='border-r border-gray-200 text-inherit flex justify-center' href={brandInfo.link1url}><span className='inline-block self-center text-center'>{brandInfo.link1text}</span></a>
                    <a className='border-r border-gray-200 text-inherit flex justify-center' href={brandInfo.link2url}><span className='inline-block self-center'>{brandInfo.link2text}</span></a>
                    <a className='border-r border-gray-200 align-middle text-inherit flex justify-center' href={brandInfo.link3url}><span className='inline-block self-center whitespace-nowrap'>{brandInfo.link3text}</span></a>
                    <a className='flex justify-center' href={brandInfo.link4url}><span className='inline-block self-center text-inherit whitespace-nowrap'>{brandInfo.link4text}</span></a>
                    <button className='border-r border-gray-200 align middle justify-center text-inherit' onClick={() => setShowCookieBanner(prev => !prev)}>Cookies</button>
                </div>
            </div>
            <CookieManager brandInfo={brandInfo} showBanner={showCookieBanner} setShowBanner={setShowCookieBanner} />
        </div>
    )


};
export default GrowveReviewsSelectFree